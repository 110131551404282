import { JBCookie } from '@bjb/bjb-web-components/dist/assets/scripts/JB.js';

window.addEventListener('experienceCloudIdReady', function(e) {
  if (localStorage.getItem('ecidSubmitted')) {
    return;
  }
  if (!e.detail.ecid) {
    return;
  }
  if (!JBCookie.getCookie('allow_tracking_cookies') && !JBCookie.getCookie('allow_marketing_cookies')) {
    return;
  }

  const endpoint = window.document.body.dataset.adobeTrackingSettingsEndpoint;
  if (!endpoint) {
    return;
  }

  const data = {
    adobeTrackingSettings: {
      ecid: e.detail.ecid,
    },
  };

  fetch(endpoint, { method: 'PUT', body: JSON.stringify(data) }).then();
  localStorage.setItem('ecidSubmitted', String(Date.now()));
});
