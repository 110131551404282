DOMTokenList.prototype.getFullModifierClassName = function (modifier) {
  if (this.length === 0) {
    return '';
  }
  return this.item(0) + '--' + modifier;
};

DOMTokenList.prototype.addModifier = function (modifier) {
  const fullModifierClassName = this.getFullModifierClassName(modifier);
  if (!fullModifierClassName) {
    return;
  }
  this.add(fullModifierClassName);
};

DOMTokenList.prototype.removeModifier = function (modifier) {
  const fullModifierClassName = this.getFullModifierClassName(modifier);
  if (!fullModifierClassName) {
    return;
  }
  this.remove(fullModifierClassName);
};

DOMTokenList.prototype.hasModifier = function (modifier) {
  const fullModifierClassName = this.getFullModifierClassName(modifier);
  if (!fullModifierClassName) {
    return false;
  }
  return this.contains(fullModifierClassName);
};

DOMTokenList.prototype.toggleModifier = function (modifier, forceValue) {
  const fullModifierClassName = this.getFullModifierClassName(modifier);
  if (!fullModifierClassName) {
    return;
  }
  if (forceValue === undefined) {
    this.toggle(fullModifierClassName);
  } else if (forceValue) {
    this.add(fullModifierClassName);
  } else {
    this.remove(fullModifierClassName);
  }
};
