function convertToAcdl(data) {
  const acdl: { event?: string; eventInfo: Record<string, any> } = {
    eventInfo: {},
  };
  if (data.event) {
    acdl.event = data.event;
    delete data.event;
  }
  acdl.eventInfo = data;
  return acdl;
}

export function addToTrackingData(data) {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push(convertToAcdl({...data}));
}

declare global {
  interface Window {
    adobeDataLayer?: Record<string, any>[];
  }
}
