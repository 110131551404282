import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-globe-icon/jb-globe-icon.scss?inline
var jb_globe_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

svg {
  fill: var(--stroke-color, rgb(var(--rgb-primary, 255, 255, 255)));
}`;

// src/components/icons/jb-globe-icon/jb-globe-icon.ts
var JbGlobeIcon = class extends h {
  render() {
    return ke`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <g>
          <g>
            <path
              d="M14.181 14.043h3.367a8.693 8.693 0 0 1-5.211 3.847c.785-.87 1.426-2.208 1.844-3.847zm-11.382 0h3.367c.418 1.64 1.059 2.976 1.844 3.847a8.693 8.693 0 0 1-5.211-3.847zM8.009 1.11c-.784.87-1.425 2.208-1.843 3.847H2.798A8.693 8.693 0 0 1 8.01 1.11zm6.172 3.847c-.418-1.64-1.06-2.976-1.845-3.847a8.69 8.69 0 0 1 5.212 3.847zm.53 4.14a19.106 19.106 0 0 0-.343-3.314h3.63a8.646 8.646 0 0 1 .828 3.314zm-1.197-3.314a18.11 18.11 0 0 1 .367 3.314H6.465a18.18 18.18 0 0 1 .368-3.314zM10.168.826c1.305 0 2.475 1.675 3.138 4.13H7.031c.663-2.455 1.833-4.13 3.137-4.13zm-7.82 4.957H5.98a19.067 19.067 0 0 0-.344 3.314H1.52a8.646 8.646 0 0 1 .828-3.314zm-.828 4.13h4.115c.023 1.177.142 2.294.344 3.314h-3.63a8.646 8.646 0 0 1-.829-3.314zm5.313 3.314a18.113 18.113 0 0 1-.368-3.314h7.417a18.184 18.184 0 0 1-.368 3.314zm3.335 4.947c-1.304 0-2.474-1.675-3.137-4.13h6.275c-.663 2.455-1.833 4.13-3.138 4.13zm7.83-4.947h-3.63c.201-1.02.321-2.137.344-3.314h4.114a8.646 8.646 0 0 1-.828 3.314zm.633-8.045C17.056 2.109 13.857 0 10.173 0 6.488 0 3.29 2.11 1.714 5.185c-.006.012-.014.023-.018.036A9.438 9.438 0 0 0 .673 9.5c0 1.539.37 2.992 1.023 4.279.004.013.012.024.018.036C3.29 16.89 6.488 19 10.174 19c3.683 0 6.882-2.109 8.457-5.182.007-.014.016-.026.02-.04A9.437 9.437 0 0 0 19.674 9.5c0-1.538-.37-2.99-1.021-4.277-.006-.015-.014-.027-.02-.041z"
            />
          </g>
        </g>
      </svg>
    `;
  }
};
JbGlobeIcon.styles = r(jb_globe_icon_default);
JbGlobeIcon = __decorateClass([
  customElement("jb-globe-icon")
], JbGlobeIcon);

export {
  JbGlobeIcon
};
