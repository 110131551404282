import {
  Rt
} from "./chunk.LTAMHVKK.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  Linkable
} from "./chunk.OF3GOBXN.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-button/jb-button.scss?inline
var jb_button_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
div.tertiary a,
div.tertiary button, div.primary a,
div.primary button, div.secondary a,
div.secondary button {
  font-family: var(--theme-font-black-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-black-weight, 900);
}

div.tertiary a,
div.tertiary button, div.primary a,
div.primary button, div.secondary a,
div.secondary button {
  font-size: var(--theme-typo-uppercase-900-fontsize, 0.688rem);
  letter-spacing: var(--theme-typo-uppercase-900-letterspacing, 0.125rem);
  line-height: var(--theme-typo-uppercase-900-lineheight, 1rem);
  text-transform: uppercase;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

div {
  cursor: pointer;
  display: inline-block;
  position: relative;
  user-select: none;
}

div.secondary {
  margin: var(--button-secondary-margin, 0);
}
div.secondary a,
div.secondary button {
  background-color: transparent;
  border: none;
  color: var(--stroke-color, rgb(var(--rgb-primary, 20, 30, 85)));
  display: inline-flex;
  padding: var(--button-padding, 10px 16px);
  text-decoration: none;
  transition: padding 0.3s ease-out 0s;
}
div.secondary::before {
  border-left: var(--border-width, 1px) solid var(--stroke-color, rgb(var(--rgb-primary, 20, 30, 85)));
  bottom: 0;
  content: "";
  height: 66%;
  left: 0;
  position: absolute;
  transition: height 0.2s ease-out 0.1s;
  width: var(--border-width, 1px);
}
div.secondary::after {
  border-bottom: var(--border-width, 1px) solid var(--stroke-color, rgb(var(--rgb-primary, 20, 30, 85)));
  bottom: 0;
  content: "";
  height: var(--border-width, 1px);
  left: 0;
  position: absolute;
  transition: width 0.3s ease-out 0s;
  width: 50%;
}
div.secondary.forcehover::before, div.secondary:hover::before {
  height: 0;
}
div.secondary.forcehover::after, div.secondary:hover::after {
  width: calc(100% + 3px);
}
@media all and (-ms-high-contrast: none) {
  div.secondary.forcehover::after, div.secondary:hover::after {
    width: 100%;
  }
}
@supports (-ms-ime-align: auto) {
  div.secondary.forcehover::after, div.secondary:hover::after {
    width: 100%;
  }
}
div.secondary.forcehover a,
div.secondary.forcehover button, div.secondary:hover a,
div.secondary:hover button {
  padding-left: var(--hover-padding-left, 36px);
  padding-right: 0;
}
div.secondary.disabled {
  opacity: 0.5;
  pointer-events: none;
}
div.secondary.disabled::before, div.secondary.disabled::after {
  opacity: 0.5;
}
div.secondary.white a,
div.secondary.white button {
  background-color: transparent;
  color: var(--stroke-color, #fff);
}
div.secondary.white::before {
  border-left: var(--border-width, 1px) solid var(--stroke-color, #fff);
}
div.secondary.white::after {
  border-bottom: var(--border-width, 1px) solid var(--stroke-color, #fff);
}

div.primary a,
div.primary button {
  border: none;
  color: var(--stroke-color, #fff);
  display: inline-flex;
  padding: 12px 18px;
  text-decoration: none;
}
div.primary a:hover::before,
div.primary button:hover::before {
  transform: scale(1.03, 1.1);
}
div.primary a::before,
div.primary button::before {
  background: rgb(var(--rgb-primary, 20, 30, 85));
  content: "";
  inset: 0;
  position: absolute;
  transition: transform 0.6s ease-in-out;
  z-index: 1;
}
div.primary a > span,
div.primary button > span {
  position: relative;
  z-index: 2;
}
div.primary.white a,
div.primary.white button {
  color: var(--stroke-color, rgb(var(--rgb-primary, 20, 30, 85)));
}
div.primary.white a::before,
div.primary.white button::before {
  background: #fff;
}

div.tertiary a,
div.tertiary button {
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--stroke-color, rgb(var(--rgb-primary, 20, 30, 85)));
  display: inline-flex;
  padding: 10px 0;
  text-decoration: none;
  transition: padding 0.3s ease-out 0s;
}
div.tertiary svg {
  flex-basis: 19px;
  flex-shrink: 0;
  position: relative;
  top: 5px;
  transition: transform 0.3s ease-out 0s;
}
div.tertiary.arrowright.forcehover svg, div.tertiary.arrowright:hover svg {
  transform: translateX(8px);
}
div.tertiary.arrowup svg {
  transform: rotate(-90deg);
}
div.tertiary.arrowup.forcehover svg, div.tertiary.arrowup:hover svg {
  transform: translateY(-3px) rotate(-90deg);
}
div.tertiary.arrowdown svg {
  transform: rotate(90deg);
}
div.tertiary.arrowdown.forcehover svg, div.tertiary.arrowdown:hover svg {
  transform: translateY(3px) rotate(90deg);
}
div.tertiary.white a,
div.tertiary.white button {
  background-color: transparent;
  color: var(--stroke-color, #fff);
}
div.tertiary.white path,
div.tertiary.white rect {
  fill: var(--stroke-color, #fff);
}
div.tertiary p {
  transition: padding 0.3s ease-out 0s;
}

div.fullwidth {
  text-align: center;
  width: 100%;
}

jb-mail-icon,
jb-print-icon {
  margin-right: 10px;
}

jb-mail-icon {
  transform: var(--translate-y, translateY(2px));
}

jb-print-icon {
  max-width: 22px;
}

jb-play-pause-button {
  display: inline-block;
  margin-right: 12px;
  vertical-align: bottom;
  --border-width: 1px;
  --icon-size: 14px;
  --icon-size-lg: var(--icon-size);
  --size: 36px;
  --size-lg: var(--size);
}`;

// src/components/jb-button/jb-button.ts
var JbButton = class extends Initiable(Linkable(h)) {
  _click(clickEvent) {
    if (!this.name || this.disabled) {
      return;
    }
    this.dispatchEvent(
      new CustomEvent("jb-button:clicked", {
        detail: { name: this.name, clickEvent },
        bubbles: true,
        composed: true
      })
    );
  }
  render() {
    const tertiaryArrow = ke`<svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_205_9894)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.9611 8.55537C17.2381 8.83236 17.2381 9.28142 16.9611 9.55838L12.3073 14.2122C12.0303 14.4892 11.5813 14.4892 11.3043 14.2122C11.0273 13.9352 11.0273 13.4862 11.3043 13.2092L15.4566 9.05688L11.3043 4.90454C11.0273 4.62755 11.0273 4.17849 11.3043 3.90153C11.5812 3.62456 12.0303 3.62454 12.3073 3.90153L16.9611 8.55537Z"
          fill="#141E55"
        />
        <rect
          x="1.14893"
          y="8.29999"
          width="15"
          height="1.5"
          fill="#141E55"
        />
      </g>
      <defs>
        <clipPath id="clip0_205_9894">
          <rect
            width="16"
            height="12"
            fill="white"
            transform="translate(1.14893 3)"
          />
        </clipPath>
      </defs>
    </svg> `;
    return ke`
      ${this.icon === "video" ? ke`<jb-play-pause-button></jb-play-pause-button>` : D}
      <div
        class=${Rt({
      disabled: this.disabled || false,
      forcehover: this.forceHover || false,
      fullwidth: this.fullWidth || false,
      white: this.white || false,
      primary: this.variant === "primary",
      secondary: this.variant === "secondary" || this.variant === void 0,
      tertiary: this.variant === "tertiary",
      arrowright: this.tertiaryArrowDirection === "right" || this.tertiaryArrowDirection === void 0,
      arrowup: this.tertiaryArrowDirection === "up",
      arrowdown: this.tertiaryArrowDirection === "down"
    })}
      >
        <a
          data-linkable
          class=${Rt({ icon: this.icon || false })}
          @click=${this._click}
        >
          ${this.icon === "mail" ? ke`<jb-mail-icon></jb-mail-icon>` : D}
          ${this.icon === "print" ? ke`<jb-print-icon></jb-print-icon>` : D}
          <span>${this.text} ${this.variant === "tertiary" ? tertiaryArrow : D}</span>
        </a>
      </div>
    `;
  }
};
JbButton.styles = r(jb_button_default);
__decorateClass([
  n({ type: Boolean })
], JbButton.prototype, "disabled", 2);
__decorateClass([
  n({ type: Boolean })
], JbButton.prototype, "forceHover", 2);
__decorateClass([
  n({ type: Boolean })
], JbButton.prototype, "fullWidth", 2);
__decorateClass([
  n()
], JbButton.prototype, "icon", 2);
__decorateClass([
  n()
], JbButton.prototype, "name", 2);
__decorateClass([
  n()
], JbButton.prototype, "tertiaryArrowDirection", 2);
__decorateClass([
  n()
], JbButton.prototype, "text", 2);
__decorateClass([
  n()
], JbButton.prototype, "variant", 2);
__decorateClass([
  n({ type: Boolean })
], JbButton.prototype, "white", 2);
JbButton = __decorateClass([
  customElement("jb-button")
], JbButton);

export {
  JbButton
};
