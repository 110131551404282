// src/assets/scripts/Stack.ts
var OverlayStack = class _OverlayStack {
  static {
    this._stack = [];
  }
  constructor() {
    _OverlayStack._domRoot = document.querySelector("#root-inner") || document.body || void 0;
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        document.dispatchEvent(
          new CustomEvent("jb-overlay:close", {
            bubbles: true,
            composed: true
          })
        );
      }
    });
  }
  add(item) {
    _OverlayStack._domRoot?.append(item);
    _OverlayStack._stack.push(item);
    return _OverlayStack._stack.length;
  }
  // Use like this: const overlayStack = OverlayStack.Instance;
  static get Instance() {
    if (!_OverlayStack._instance) {
      _OverlayStack._instance = new this();
    }
    return _OverlayStack._instance;
  }
  remove(item) {
    _OverlayStack._stack = _OverlayStack._stack.filter((i) => i !== item);
    return _OverlayStack._stack.length;
  }
  static _removeTopOverlay() {
    if (_OverlayStack._stack.length === 0) {
      return;
    }
    _OverlayStack._stack[_OverlayStack._stack.length - 1].unstack();
  }
};

export {
  OverlayStack
};
