class DataLayer {
  static add(data) {
    if (!window.adobeDataLayer) {
      window.adobeDataLayer = [];
    }
    window.adobeDataLayer.push({
      event: data.event,
      eventInfo: data,
    });
    document.dispatchEvent(
      new CustomEvent(data.event, {
        detail: data,
      })
    );
  }
}

export default DataLayer;
