import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/mixins/Filterable.ts
function Filterable(baseClass) {
  class FilterableMixin extends baseClass {
    markAsDisabled() {
      this.setAttribute("data-disabled-by-filter", "");
    }
    markAsEnabled() {
      this.removeAttribute("data-disabled-by-filter");
    }
    matchesFilterValue(filterValueToCompareTo) {
      return !!this.filterValues?.find(
        (filterValue) => filterValue.filterAspect === filterValueToCompareTo.filterAspect && filterValue.value === filterValueToCompareTo.value
      );
    }
  }
  __decorateClass([
    n({ type: Array })
  ], FilterableMixin.prototype, "filterValues", 2);
  return FilterableMixin;
}

export {
  Filterable
};
