import { JBCookie } from '@bjb/bjb-web-components/dist/assets/scripts/JB.js';

function loadAdobeAnalytics(adobeTrackingUrl: string) {
  if (document.querySelector('[data-adobe-script-tag]')) {
    return;
  }

  const firstScriptTag = document.getElementsByTagName('script')[0];
  const adobeScriptTag = document.createElement('script');
  adobeScriptTag.src = adobeTrackingUrl;
  adobeScriptTag.setAttribute('async', '');
  adobeScriptTag.setAttribute('data-adobe-script-tag', '');
  firstScriptTag.parentNode!.insertBefore(adobeScriptTag, firstScriptTag);
}

function loadTrackingOnConsent() {
  if (!JBCookie.getCookie('allow_tracking_cookies') && !JBCookie.getCookie('allow_marketing_cookies')) {
    return;
  }
  const { adobeTrackingUrl } = window;
  if (adobeTrackingUrl) {
    loadAdobeAnalytics(adobeTrackingUrl);
  }
}

loadTrackingOnConsent();
document.addEventListener('jb-cookie-settings:update', loadTrackingOnConsent);

declare global {
  interface Window {
    adobeDataLayer?: Record<string, any>[];
    adobeTrackingUrl?: string;
  }
}
