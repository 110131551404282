const linkFeatures = {
  scope: null,
  init(scope) {
    this.scope = scope || document;
    this._initializeAllLinks();
  },
  _initializeAllLinks() {
    this.scope.querySelectorAll('[data-jblinkdata]').forEach((element) => {
      this._registerClickEvent(element);
      this._sendInitializationEvent(element);
    });
  },
  _registerClickEvent(element) {
    element.addEventListener('click', (clickEvent) => {
      const detail = JSON.parse(element.dataset.jblinkdata);
      detail.linkTag = element;
      detail.clickEvent = clickEvent;
      const customEvent = new CustomEvent('jb-link:clicked', {
        detail,
        bubbles: true,
        composed: true,
      });
      clickEvent.target.dispatchEvent(customEvent);
    });
  },
  _sendInitializationEvent(element) {
    const detail = JSON.parse(element.dataset.jblinkdata);
    detail.linkTag = element;
    const customEvent = new CustomEvent('jb-link:initialized', {
      bubbles: true,
      detail,
    });
    element.dispatchEvent(customEvent);
  },
};

export default linkFeatures;
