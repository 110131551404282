import DataLayer from '../componentClasses/DataLayer';

const gaEvents = {
  registerEvents() {
    document.body.addEventListener('searchResultItemClicked', (event) => {
      let date = null;
      if (event.detail.contentWrap.querySelector('.component-search-result-item__date')) {
        date = event.detail.contentWrap.querySelector('.component-search-result-item__date').textContent;
      }
      const GaData = {
        component_name: 'search_result',
        event: 'ce_search_results_click',
        search_term: event.detail.inputValue !== '' ? event.detail.inputValue : '',
        search_results_count: event.detail.totalResultsCount,
        search_results_click_title: event.detail.contentWrap.querySelector('.component-search-result-item__title').textContent,
        search_results_click_url: event.detail.contentWrap.querySelector('.component-search-result-item__link').href,
        search_results_click_date: date,
        search_results_click_type: event.detail.contentWrap.previousElementSibling.textContent.trim(),
      };
      DataLayer.add(GaData);
    });
  },
};

export default gaEvents;
