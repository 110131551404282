import {
  Rt
} from "./chunk.LTAMHVKK.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  Linkable
} from "./chunk.OF3GOBXN.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ButtonElement
} from "./chunk.7PRSKJZQ.js";
import {
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-button-primary/jb-button-primary.scss?inline
var jb_button_primary_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
a,
button {
  font-family: var(--theme-font-black-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-black-weight, 900);
}

a,
button {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 0.688rem;
  letter-spacing: 0.125rem;
  line-height: 1rem;
  text-decoration: none;
  text-transform: uppercase;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host,
::before,
::after {
  display: inline-block;
  inline-size: var(--button-width, initial);
  --_button-transition-duration: 2s;
  --_button-transition-easing: cubic-bezier(0.16, 1, 0.3, 1);
  --_button-bottom-border-width: 1px;
  --_button-bottom-border-spacing: 2px;
  --_button-text-color: #fff;
  --_button-background-color: #141e55;
  --_icon-color: var(--_button-text-color);
}

:host {
  clip-path: inset(0);
}

::slotted(*) {
  margin-inline-end: 0.5rem;
  --icon-color: var(--_button-text-color);
}

a,
button {
  background-color: var(--_button-background-color);
  border: 0;
  color: var(--_button-text-color);
  cursor: pointer;
  display: flex;
  inline-size: 100%;
  justify-content: center;
  margin-block-end: calc(var(--_button-bottom-border-spacing) + var(--_button-bottom-border-width));
  padding-block: 12px calc(12px - var(--_button-bottom-border-spacing) - var(--_button-bottom-border-width));
  padding-inline: 34px;
  position: relative;
  text-align: center;
  user-select: none;
}
a::after,
button::after {
  background-color: var(--_button-background-color);
  block-size: var(--_button-bottom-border-width);
  content: "";
  inline-size: 100%;
  inset-block-end: calc(-1 * var(--_button-bottom-border-width));
  inset-inline-end: 0;
  position: absolute;
  transition: width var(--_button-transition-duration) var(--_button-transition-easing);
}
a.hover::after, a:hover::after,
button.hover::after,
button:hover::after {
  inline-size: 0;
}
a::before,
button::before {
  background-color: var(--_button-background-color);
  block-size: var(--_button-bottom-border-spacing);
  content: "";
  inline-size: 100%;
  inset-block-end: calc(-1 * var(--_button-bottom-border-width) - var(--_button-bottom-border-spacing));
  inset-inline-start: 0;
  position: absolute;
}
a:active,
button:active {
  transform: scale(0.99);
}

.inverted,
.inverted::after,
.inverted::before {
  --_button-text-color: #141e55;
  --_button-background-color: #fff;
}

.inactive {
  opacity: 0.4;
  pointer-events: none;
}`;

// src/components/jb-button-primary/jb-button-primary.ts
var JbButtonPrimary = class extends Initiable(ButtonElement(Linkable(h))) {
  constructor() {
    super(...arguments);
    this.forceHover = false;
    this.inactive = false;
    this.inverted = false;
    this.name = void 0;
  }
  render() {
    const classes = { inactive: !!this.inactive, hover: !!this.forceHover, inverted: !!this.inverted };
    return this.link?.href ? ke`<a
          data-linkable
          class=${Rt(classes)}
        >
          <slot></slot>
        </a>` : ke`<button
          ?disabled=${this.inactive}
          class=${Rt(classes)}
        >
          <slot></slot>
        </button>`;
  }
};
JbButtonPrimary.styles = r(jb_button_primary_default);
__decorateClass([
  n({ type: Boolean })
], JbButtonPrimary.prototype, "forceHover", 2);
__decorateClass([
  n({ type: Boolean })
], JbButtonPrimary.prototype, "inactive", 2);
__decorateClass([
  n({ type: Boolean })
], JbButtonPrimary.prototype, "inverted", 2);
__decorateClass([
  n()
], JbButtonPrimary.prototype, "name", 2);
JbButtonPrimary = __decorateClass([
  customElement("jb-button-primary")
], JbButtonPrimary);

export {
  JbButtonPrimary
};
