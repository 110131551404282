import styleSelect from 'styleselect';

const selectList = {
  relatedTags: null,
  letterTyped: null,
  currentOpen: null,
  init() {
    if (!selectList.dom()) {
      return;
    }

    selectList.bindEvents();
    selectList.isOpenedSelect();
    selectList.createAnimateLine();
  },
  dom() {
    selectList.selectLists = Array.from(document.querySelectorAll('.select-list'));
    selectList.list = document.querySelectorAll('select');
    return selectList.selectLists.length > 0;
  },
  bindEvents() {
    selectList.selectLists.forEach((element) => {
      styleSelect(element);
    });

    if (selectList.list !== null) {
      const styledSelectList = document.querySelectorAll('.style-select');
      let styledSelectListOpened = null;
      styledSelectList.forEach((item) => {
        const selectedOption = item.querySelector('.ss-selected-option');
        selectedOption.addEventListener('click', () => {
          styledSelectListOpened = item;
          const label = item.parentNode.querySelector('label');
          if (item.classList.contains('open')) {
            label.classList.add('active');
          } else {
            label.classList.remove('active');
            styledSelectListOpened = null;
          }
        });
      });

      document.body.addEventListener('click', () => {
        if (styledSelectListOpened !== null) {
          const label = styledSelectListOpened.parentNode.querySelector('label');
          label.classList.remove('active');
        }
      });
    }

    document.addEventListener('keydown', (event) => {
      selectList.currentOpen = document.querySelector('.style-select.open');
      if (event.key === 'Enter' && selectList.currentOpen !== null) {
        selectList.currentOpen.classList.remove('open');
        selectList.currentOpen = null;
      } else {
        selectList.letterTyped = event.key.toLowerCase();
        selectList.findItemWhichStartsWith();
      }
    });
  },

  isOpenedSelect() {
    const styleSelectLists = document.querySelectorAll('.style-select .ss-selected-option');
    styleSelectLists.forEach((item) => {
      item.addEventListener('click', () => {
        selectList.letterTyped = null;
        if (item.parentNode.classList.contains('open')) {
          selectList.currentOpen = item.parentElement;
        } else {
          selectList.currentOpen = null;
        }
      });
    });
  },
  findItemWhichStartsWith() {
    let found = false;
    if (selectList.currentOpen !== null) {
      const items = selectList.currentOpen.querySelectorAll('.animate-line');
      const highlighted = selectList.currentOpen.querySelectorAll('.highlighted');
      if (highlighted.length > 0) {
        highlighted.forEach((item) => {
          item.classList.remove('highlighted');
        });
      }
      if (items.length > 0) {
        items.forEach((item) => {
          if (!found) {
            if (item.textContent.startsWith(selectList.letterTyped.toUpperCase()) || item.textContent.startsWith(selectList.letterTyped.toLowerCase())) {
              // eslint-disable-next-line no-param-reassign
              item.parentNode.parentNode.scrollTop = item.parentNode.offsetTop;
              item.parentNode.classList.add('highlighted');
              const ticked = selectList.currentOpen.querySelector('.ticked');
              const selectedOptionLabel = selectList.currentOpen.querySelector('.ss-selected-option');
              selectedOptionLabel.textContent = item.textContent;
              selectedOptionLabel.dataset.value = item.parentNode.dataset.value;
              ticked.classList.remove('ticked');
              item.parentNode.classList.add('ticked');
              found = true;
            }
          }
        });
      }
    }
  },
  createAnimateLine() {
    // running this code within a timeout prevents race conditions (BJBWU-805)
    setTimeout(() => {
      const selectOptions = document.querySelectorAll('.ss-option');

      selectOptions.forEach((item) => {
        const pTag = document.createElement('p');
        pTag.classList.add('animate-line');
        pTag.innerHTML = item.innerHTML;
        // eslint-disable-next-line no-param-reassign
        item.innerHTML = '';
        item.appendChild(pTag);
      });
    });
  },
};

export default selectList;
