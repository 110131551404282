import JB from '../../scripts/JB';

const articleProgressBar = {
  articleContent: null,
  line: null,
  progressBar: null,
  init() {
    articleProgressBar.articleContent = document.querySelector('main');
    articleProgressBar.progressBar = document.querySelector('.component-special-article-progress-bar');
    if (!articleProgressBar.articleContent || !articleProgressBar.progressBar) {
      return;
    }
    articleProgressBar.line = articleProgressBar.progressBar.querySelector('.component-special-article-progress-bar__line');
    articleProgressBar.bindEvents();
  },
  bindEvents() {
    window.addEventListener('scroll', JB.debounce(this.handleScroll, 10, false));
    document.body.addEventListener('jb-navigation:appearance-changed', this.handleNavigationStickyChange);
  },
  handleScroll() {
    articleProgressBar.currentProgress();
  },
  handleNavigationStickyChange(e) {
    const navigationIsStickyAndVisible = e.detail.fixed && !e.detail.hidden;
    articleProgressBar.progressBar.classList.toggleModifier('bottom', navigationIsStickyAndVisible);
  },
  currentProgress() {
    const winScroll = (document.body.scrollTop || document.documentElement.scrollTop) - articleProgressBar.articleContent.offsetTop + window.innerHeight;
    const h = articleProgressBar.articleContent.clientHeight;
    const scrolled = (winScroll / h) * 100;
    if (winScroll > window.innerHeight) {
      articleProgressBar.line.style.width = scrolled + '%';
    } else {
      articleProgressBar.line.style.width = 0;
    }
  },
};

export default articleProgressBar;
