import {
  Spaceable
} from "./chunk.UQO6F5RO.js";
import {
  Rt
} from "./chunk.LTAMHVKK.js";
import {
  jbLinkClickedEventToJbTrackingEvent,
  setLink
} from "./chunk.OF3GOBXN.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n,
  r2
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-campaign-teaser/jb-campaign-teaser.scss?inline
var jb_campaign_teaser_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
h2 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

h2 {
  font-size: var(--theme-typo-h2-image-mobile-fontsize, 1.938rem);
  line-height: var(--theme-typo-h2-image-mobile-lineheight, 2.625rem);
}
@media (min-width: 1281px) {
  h2 {
    font-size: var(--theme-typo-h2-image-desktop-fontsize, 2.813rem);
    line-height: var(--theme-typo-h2-image-desktop-lineheight, 3.813rem);
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
  overflow: hidden;
}

section {
  color: rgb(var(--rgb-primary, 255, 255, 255));
  inset: 0;
  position: relative;
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 0;
  width: 100%;
}
@media (min-width: 960px) {
  section {
    clip-path: inset(0 20px);
  }
}
@media (min-width: 600px) {
  section {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  section > *,
  section iframe {
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;
  }
  section::before {
    content: "";
    display: block;
    height: 0;
    padding-bottom: calc(9 / 21 * 100%);
    position: relative;
    width: 100%;
  }
}
@media (min-width: 960px) {
  section {
    margin-left: 8.3333333333%;
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
    padding: 0 20px;
    width: 83.3333333333%;
  }
}

.bg {
  height: 100%;
  inset: 0;
  position: absolute;
  transform: scale(1);
  transition: transform 0.5s ease 0s;
  width: auto;
  z-index: 1;
}
@media (min-width: 960px) {
  .bg {
    inset-block: 0;
    left: 20px;
    right: 20px;
  }
}
.bg::after {
  background-color: rgba(0, 0, 0, 0.3);
  content: "";
  inset: 0;
  position: inherit;
}

.hover {
  transform: scale(1.03);
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-height: 450px;
  padding: 40px 25px;
  position: relative;
  width: 100%;
  z-index: 2;
}
@media (min-width: 600px) {
  .content {
    min-height: auto;
    padding: 40px 72px;
    position: absolute;
  }
}
@media (min-width: 960px) {
  .content {
    padding: 32px 80px;
  }
}
@media (min-width: 1281px) {
  .content {
    padding: 60px 90px;
  }
}
.content > div {
  text-align: center;
}

.btn-panel {
  margin-top: 20px;
}

jb-button-primary {
  margin-top: 20px;
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 0;
  width: 100%;
}
@media (min-width: 600px) {
  jb-button-primary {
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    padding: 0 20px;
    width: 66.6666666667%;
  }
}
@media (min-width: 960px) {
  jb-button-primary {
    flex-basis: unset;
    max-width: unset;
    width: unset;
  }
}

jb-picture {
  height: 100%;
}

.mobile-app-icon {
  display: inline-block;
  transform: scale(1);
  transition: transform 1s ease 0s;
  z-index: 1;
}
@media (min-width: 600px) {
  .mobile-app-icon {
    margin-right: 20px;
  }
}
.mobile-app-icon:hover {
  transform: scale(1.05);
  transition: transform 1s ease 0s;
}
.mobile-app-icon:last-child {
  margin-right: 0;
}

jb-section-headline {
  color: rgb(var(--rgb-primary, 255, 255, 255));
}`;

// src/components/jb-campaign-teaser/jb-campaign-teaser.ts
var JbCampaignTeaser = class extends Spaceable(h) {
  constructor() {
    super(...arguments);
    this.appButtons = [];
    this.forceHover = false;
    this.headline = "";
    this.subtitle = "";
  }
  async firstUpdated() {
    this._anchortags?.forEach((element) => {
      setLink(element, { href: element.href, data: { type: "external" } });
    });
  }
  _getContentSpacingProperties() {
    return { horizontal: "full-width-md-down", colsMdUp: "none" };
  }
  _tracking(e) {
    const interactionText = e.detail.linkableComponent?.innerText ?? e.detail.linkTag.href;
    const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e, {
      component_name: "campaign-teaser",
      interaction_text: interactionText
    });
    if (jbTrackingEvent) {
      this.dispatchEvent(jbTrackingEvent);
    }
  }
  render() {
    return ke`
      <section>
        <div class=${Rt({ bg: true, hover: !!this.forceHover })}>
          <jb-picture
            .alt=${this.bgImage?.alt}
            .imgData=${this.bgImage?.imgData}
            ?lazy=${this.bgImage?.lazy}
            fit="cover"
          ></jb-picture>
        </div>
        <div class="content">
          ${this.headline ? ke`<jb-section-headline headline=${this.headline}></jb-section-headline>` : D}
          <div>
            ${this.subtitle ? ke`<h2>${this.subtitle}</h2>` : D}
            <div
              class="btn-panel"
              @mouseenter=${() => {
      this.forceHover = true;
    }}
              @mouseleave=${() => {
      this.forceHover = false;
    }}
              @jb-link:clicked=${this._tracking}
            >
              ${this.appButtons ? ke`
                    ${this.appButtons.map(
      (appButton) => ke`
                        <a
                          class="mobile-app-icon"
                          href="${appButton.url}"
                          ><img
                            src="${appButton.buttonImagePath}"
                            alt="${appButton.url}"
                        /></a>
                      `
    )}
                  ` : D}
              ${this.button?.text ? ke`<jb-button-primary
                    inverted
                    .link=${this.button.link}
                    >${this.button.text}</jb-button-primary
                  >` : D}
            </div>
          </div>
        </div>
      </section>
    `;
  }
};
JbCampaignTeaser.styles = r(jb_campaign_teaser_default);
__decorateClass([
  n({ type: Array })
], JbCampaignTeaser.prototype, "appButtons", 2);
__decorateClass([
  n({ type: Object })
], JbCampaignTeaser.prototype, "bgImage", 2);
__decorateClass([
  n({ type: Object })
], JbCampaignTeaser.prototype, "button", 2);
__decorateClass([
  n({ type: Boolean })
], JbCampaignTeaser.prototype, "forceHover", 2);
__decorateClass([
  n()
], JbCampaignTeaser.prototype, "headline", 2);
__decorateClass([
  n()
], JbCampaignTeaser.prototype, "subtitle", 2);
__decorateClass([
  r2(".mobile-app-icon")
], JbCampaignTeaser.prototype, "_anchortags", 2);
JbCampaignTeaser = __decorateClass([
  customElement("jb-campaign-teaser")
], JbCampaignTeaser);

export {
  JbCampaignTeaser
};
