import "../../chunks/chunk.I4IFG4JG.js";

// src/assets/scripts/FallbackTracking.ts
if (window.adobeDataLayer === void 0) {
  window.adobeDataLayer = [];
}
function convertToAcdl(event) {
  const eventDetails = event.detail;
  const acdl = {
    eventInfo: {},
    event: {}
  };
  if (eventDetails.event) {
    acdl.event = eventDetails.event;
    delete eventDetails.event;
  }
  acdl.eventInfo = eventDetails;
  return acdl;
}
document.body.addEventListener("jb-tracking", (event) => {
  window.adobeDataLayer.push(convertToAcdl(event));
});
function isOutboundLink(linkUrl) {
  const outboundLinkRegex = new RegExp(`^(https?://)(?!${window.location.hostname}).+`);
  return outboundLinkRegex.test(linkUrl);
}
function isDownloadLink(url, isDownload = false) {
  const downloadFileTypes = "doc|docx|eps|xls|ppt|pptx|pdf|xlsx|tab|csv|zip|txt|vsd|vxd|xml|js|css|rar|exe|wma|mov|avi|wmv|mp3|wav|m4v|ics|msg|ical|ics|ifb|icalendar";
  const regExp = new RegExp("\\.(?:" + downloadFileTypes + ")($|&|\\?)");
  return regExp.test(url) || isDownload;
}
function isMailtoLink(url) {
  return url.slice(0, 7) === "mailto:";
}
function isPhoneLink(url) {
  return url.slice(0, 4) === "tel:";
}
function buildComponentName(url, isDownload = false) {
  if (isOutboundLink(url)) return "generic-outbound-link-tracking";
  if (isDownloadLink(url, isDownload)) return "generic-download-link-tracking";
  if (isMailtoLink(url)) return "generic-email-link-tracking";
  if (isPhoneLink(url)) return "generic-phone-link-tracking";
  return "generic-internal-link-tracking";
}
function buildEvent(url, isDownload = false) {
  if (isDownloadLink(url, isDownload)) return "ce_download";
  return "ce_interaction";
}
function buildInteractionEffect(url, isDownload = false) {
  if (isDownloadLink(url, isDownload)) return "download";
  if (isMailtoLink(url)) return "open_email";
  if (isPhoneLink(url)) return "start_phone_call";
  return "navigation";
}
function buildInteractionElement(url, isDownload = false) {
  if (isOutboundLink(url)) return "External Links";
  if (isDownloadLink(url, isDownload)) return "Download Links";
  if (isMailtoLink(url)) return "Mailto Links";
  if (isPhoneLink(url)) return "Phone Links";
  return "Internal Links";
}
document.addEventListener("jb-link:clicked", (event) => {
  const eventDetails = event.detail;
  const url = eventDetails.linkTag.href.trim();
  if (eventDetails.noGenericLinkTracking) return;
  const eventDetail = {
    component_name: buildComponentName(url, eventDetails.downloadFileName),
    event: buildEvent(url, eventDetails.downloadFileName),
    event_version: "v2",
    interaction_effect: buildInteractionEffect(url, eventDetails.downloadFileName),
    interaction_element: buildInteractionElement(url, eventDetails.downloadFileName),
    interaction_result: new URL(url).href,
    interaction_text: eventDetails.linkTag.innerText,
    interaction_type: "click"
  };
  if (eventDetails.downloadFileName) {
    Object.assign(eventDetail, {
      file_name: eventDetails.downloadFileName.split(".").shift(),
      file_type: eventDetails.downloadFileName.split(".").pop()
    });
  }
  document.body.dispatchEvent(
    new CustomEvent("jb-tracking", {
      detail: eventDetail,
      bubbles: true,
      composed: true
    })
  );
});
var { adobeDataLayer } = window;
export {
  adobeDataLayer
};
