const navigationTags = {
  relatedTags: null,
  init() {
    navigationTags.dom();
  },
  dom() {
    navigationTags.relatedTags = document.querySelectorAll('.navigation-tags--with-underline .navigation-tags__list .navigation-tags__list-item');
    if (navigationTags.relatedTags.length > 0) {
      navigationTags.bindEvents();
    }
  },
  bindEvents() {
    navigationTags.relatedTags.forEach((el) => {
      el.addEventListener('click', function () {
        const parent = el.parentNode;
        const active = parent.querySelector('.navigation-tags__list-item--active');
        if (active !== null) {
          active.classList.removeModifier('active');
        }
        this.classList.addModifier('active');
      });
    });
  },
};

export default navigationTags;
