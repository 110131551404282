import {
  Spaceable
} from "./chunk.UQO6F5RO.js";
import {
  Rt
} from "./chunk.LTAMHVKK.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-quote/jb-quote.scss?inline
var jb_quote_default = i`@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
p, footer, .pullquote footer, .pullquote p {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

.pullquote p {
  font-size: var(--theme-typo-h2-subtitle-mobile-fontsize, 1.625rem);
  line-height: var(--theme-typo-h2-subtitle-mobile-lineheight, 2.188rem);
}
@media (min-width: 1281px) {
  .pullquote p {
    font-size: var(--theme-typo-h2-subtitle-desktop-fontsize, 1.938rem);
    line-height: var(--theme-typo-h2-subtitle-desktop-lineheight, 2.625rem);
  }
}

.pullquote footer {
  font-size: var(--theme-typo-p-small-desktop-fontsize, 1rem);
  line-height: var(--theme-typo-p-small-desktop-lineheight, 1.625rem);
}

footer {
  color: rgba(var(--rgb-primary, 20, 30, 85), var(--text-opacity, var(--theme-opacity-80, 0.8)));
  font-size: 1rem;
  line-height: 1.625rem;
}

p {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 1.625rem;
  line-height: 2.188rem;
}
@media (min-width: 1281px) {
  p {
    font-size: 1.938rem;
    line-height: 2.625rem;
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

p {
  color: #141e55;
  margin-block-end: var(--spacing-s);
}
p::before {
  content: "“";
}
p::after {
  content: "”";
}

.pullquote {
  border-inline-start: 1px solid #b0aa7e;
  padding-block: var(--spacing-3xs);
  padding-inline-start: var(--spacing-m);
}
.pullquote p {
  font-style: italic;
}
.pullquote footer {
  color: rgba(20, 30, 85, var(--theme-opacity-80, 0.8));
}`;

// src/components/jb-quote/jb-quote.ts
var JbQuote = class extends Spaceable(h) {
  _getContentSpacingProperties() {
    return { grid: "legacy", colsMdUp: 8, type: "text" };
  }
  render() {
    return ke`
      <blockquote class="${Rt({ pullquote: !!this.isPullQuote })}">
        <p>${this.quote}</p>
        <footer>${this.author}</footer>
      </blockquote>
    `;
  }
};
JbQuote.styles = r(jb_quote_default);
__decorateClass([
  n()
], JbQuote.prototype, "author", 2);
__decorateClass([
  n({ type: Boolean })
], JbQuote.prototype, "isPullQuote", 2);
__decorateClass([
  n()
], JbQuote.prototype, "quote", 2);
JbQuote = __decorateClass([
  customElement("jb-quote")
], JbQuote);

export {
  JbQuote
};
