import {
  le
} from "./chunk.Q25FCJRM.js";
import {
  e
} from "./chunk.OZEBCGEZ.js";

// node_modules/lit-html/directives/unsafe-svg.js
var pe = class extends le {
};
pe.directiveName = "unsafeSVG", pe.resultType = 2;
var fe = e(pe);

export {
  fe
};
/*! Bundled license information:

lit-html/directives/unsafe-svg.js:
  (**
   * @license
   * Copyright 2017 Google LLC
   * SPDX-License-Identifier: BSD-3-Clause
   *)
*/
