import { JBCookie } from '@bjb/bjb-web-components/dist/assets/scripts/JB.js';

const alertHandling = {
  init() {
    const i18nCookieData = JBCookie.getI18nCookieData();

    // @ts-ignore
    const alerts = (typeof alertsJson === 'undefined') ? null : alertsJson;
    if (!alerts) {
      return;
    }

    if (alerts.initial) {
      alertHandling.handleInitialAlert(alerts.initial);
    }
    if (alerts.prepared) {
      const alertsRelevantForMarket = alerts.prepared.filter((singleAlert) => {
        if (!singleAlert.additionalData.forMarketUid) {
          return true;
        }
        return parseInt(singleAlert.additionalData.forMarketUid, 10) === parseInt(i18nCookieData.market, 10);
      });
      if (alertsRelevantForMarket.length === 0) {
        return;
      }
      alertHandling.handleGateAlerts(alerts.prepared);
      alertHandling.handleMarketLeaveAlerts(alertsRelevantForMarket);
      alertHandling.handlePageEnterAlerts(alertsRelevantForMarket);
    }
  },
  handleInitialAlert(alertData) {
    if (!this.shouldBeDisplayed(alertData.id, alertData.additionalData.repeats)) {
      return;
    }
    localStorage.setItem('alertDisplayed_' + alertData.id, this.now + '');
    this.openAlert(alertData);
  },
  handleGateAlerts(preparedAlerts) {
    const gateAlerts = preparedAlerts.filter((alertToTest) => {
      if (!alertToTest.additionalData) {
        return false;
      }
      return alertToTest.additionalData.type === 'gateAlert';
    });

    if (gateAlerts.length > 0) {
      document.body.addEventListener('jb-link:clicked', (event: CustomEvent) => {
        if (!event.detail.gateAlertUid) {
          return;
        }
        const matchingGateAlert = gateAlerts.find((singleAlert) => singleAlert.id === 'gateAlert_' + event.detail.gateAlertUid);
        if (matchingGateAlert) {
          event.stopPropagation();
          event.detail.clickEvent.preventDefault();
          this.openAlert(matchingGateAlert);
        }
      });
    }
  },
  handleMarketLeaveAlerts(preparedAlerts) {
    const marketLeaveAlerts = preparedAlerts.filter((alertToTest) => {
      if (!alertToTest.additionalData) {
        return false;
      }
      return alertToTest.additionalData.type === 'marketLeaveAlert';
    });
    if (marketLeaveAlerts.length > 0) {
      document.body.addEventListener('jb-link:clicked', (event: CustomEvent) => {
        if (!event.detail.leaveAlertPageId) {
          return;
        }
        const matchingMarketLeaveAlert = marketLeaveAlerts.find((singleAlert) => singleAlert.additionalData.pages.indexOf(event.detail.leaveAlertPageId) > -1);
        if (matchingMarketLeaveAlert) {
          event.stopPropagation();
          event.detail.clickEvent.preventDefault();
          matchingMarketLeaveAlert.buttons[0].href = event.detail.linkTag.getAttribute('href');
          this.openAlert(matchingMarketLeaveAlert);
        }
      });
    }
  },
  handlePageEnterAlerts(preparedAlerts) {
    const pageEnterAlerts = preparedAlerts.filter((alertToTest) => {
      if (!alertToTest.additionalData) {
        return false;
      }
      return alertToTest.additionalData.type === 'pageEnterAlerts';
    });
    if (pageEnterAlerts.length === 0) {
      return;
    }

    // initial alert
    if (document.referrer.indexOf(document.location.protocol + '//' + document.location.host) === -1) {
      pageEnterAlerts.forEach((pageEnterAlert) => {
        if (pageEnterAlert.additionalData.pages.indexOf(pageEnterAlert.additionalData.currentPage) === -1) {
          return;
        }
        // eslint-disable-next-line no-param-reassign
        pageEnterAlert.buttons[0].closesTheOverlay = true;
        this.openAlert(pageEnterAlert);
      });
    }

    // alert when clicking link that enters pageEnterAlerts
    document.body.addEventListener('jb-link:clicked', (event: CustomEvent) => {
      if (!event.detail.leaveAlertPageId) {
        return;
      }
      const matchingPageEnterAlert = pageEnterAlerts.find((singleAlert) => {
        const linkTargetPageIsInAlertTargetPages = singleAlert.additionalData.pages.indexOf(event.detail.leaveAlertPageId) > -1;
        const currentPageIsInAlertTargetPages = singleAlert.additionalData.pages.indexOf(singleAlert.additionalData.currentPage) > -1;
        return linkTargetPageIsInAlertTargetPages && !currentPageIsInAlertTargetPages;
      });
      if (!matchingPageEnterAlert) {
        return;
      }

      event.stopPropagation();
      event.detail.clickEvent.preventDefault();
      matchingPageEnterAlert.buttons[0].href = event.detail.linkTag.getAttribute('href');
      this.openAlert(matchingPageEnterAlert);
    });
  },

  shouldBeDisplayed(id, repeats) {
    if (repeats === 'always') {
      return true; // alert is configured to be always displayed => true
    }
    const lastDisplayed = localStorage.getItem('alertDisplayed_' + id);
    if (!lastDisplayed) {
      return true; // alert was never displayed before => true
    }
    if (repeats === 'never' || repeats === '') {
      return false; // alert was displayed before and is configured to never repeat => false
    }
    if (repeats !== 'daily') {
      console.error('Unrecognized value "' + repeats + '" as repeats property of pageAlert');
      return false;
    }

    return parseInt(lastDisplayed, 10) < this.now - 24 * 60 * 60; // true if lastDisplayed is at least 24h ago
  },
  openAlert(alertData) {
    document.createElement('jb-popup').init({
      childNodes: [
        document.createElement('jb-alert').init({
          props: {
            title: alertData.texts.title,
            content: alertData.texts.content,
          },
          childNodes: alertData.buttons?.map((singleButton) => {
            const button = document.createElement('jb-button-primary').init({
              props: {
                inverted: true,
                link: { href: singleButton.href },
              },
            });
            button.setAttribute('slot', 'buttons');
            button.innerHTML = singleButton.text;
            if (singleButton.closesTheOverlay) {
              button.addEventListener('click', (e) => {
                e.preventDefault();
                button.closest('jb-popup').unstack();
              });
            }
            return button;
          }),
        }),
      ]
    }).stack();
  },
}

alertHandling.init();
