import { JBCookie } from '@bjb/bjb-web-components/dist/assets/scripts/JB.js';
import { JbForm } from '../../../../storybook/src/components/jb-form/jb-form';

window.addEventListener(
  'load',
  () => {
    document.querySelectorAll('jb-form[data-disclaimer-id]').forEach((form: HTMLFormElement) => {
      (form.closest('jb-content-spacing') as HTMLElement | undefined)?.style.setProperty('--vertical-spacing-factor', '0');

      form.querySelector('jb-button[name="agree"]')?.addEventListener('click', () => {
        const { disclaimerId } = form.dataset;
        if (!disclaimerId) {
          return;
        }
        const valid = (form as unknown as JbForm).validate();
        if (!valid) {
          return;
        }

        JBCookie.setCookie(`disclaimer_${disclaimerId}`, '1');
        window.document.location.reload();
      });
      form.querySelector('jb-button[name="disagree"]')?.addEventListener('click', () => {
        window.document.location.href = '/';
      });

      form.addEventListener('submit', (submitEvent) => {
        const { disclaimerId } = form.dataset;
        if (!disclaimerId) {
          return;
        }

        JBCookie.setCookie(`disclaimer_${disclaimerId}`, '1');
      });
    });
  },
  { once: true }
);
