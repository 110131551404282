export class LazyLoadElement {
  constructor(componentName: string, callback: () => void) {
    window.addEventListener(
      'load',
      () => {
        const componentElement = document.querySelector(componentName);
        if (!componentElement) return;

        const componentObserver = new IntersectionObserver(
          async (entries, observer) => {
            if (entries[0].isIntersecting && typeof customElements.get(componentName) === 'undefined') {
              callback();
              observer.disconnect();
            }
          },
          {
            rootMargin: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) + 'px',
          }
        );

        componentObserver.observe(componentElement);
      },
      { once: true }
    );
  }
}
