// TODO: This JS is mainly for moving the placeholder from the input to the top. This can IMO be done by pure CSS.
// This is also be done in the textarea component
// https://jsfiddle.net/273ntk5s/2/
const inputFields = {
  init() {
    inputFields.dom();
    inputFields.bindEvents();
  },
  dom() {
    inputFields.inputs = document.querySelectorAll('.input');
  },
  bindEvents() {
    if (inputFields.inputs !== null) {
      inputFields.inputs.forEach((item) => {
        item.addEventListener('focusin', inputFields.hidePlaceholder);
        item.addEventListener('focusout', inputFields.showPlaceholder);
      });
    }
  },
  hidePlaceholder() {
    let currentActive = this.querySelector('input');
    if (currentActive === null) {
      currentActive = this.querySelector('textarea');
    }
    const label = this.querySelector('label');
    if (currentActive !== null) {
      label.classList.add('active');
      currentActive.classList.add('active');
    }
  },
  scrollElementIntoViewOnFocus(element, behavior) {
    const scrollTop = window.pageYOffset || element.scrollBottom;
    const finalOffset = element.getBoundingClientRect().bottom + scrollTop;
    window.parent.scrollTo({
      bottom: finalOffset,
      behavior: behavior || 'auto',
    });
  },
  showPlaceholder() {
    let currentActive = this.querySelector('input');
    if (currentActive === null) {
      currentActive = this.querySelector('textarea');
    }
    const label = this.querySelector('label');
    if (currentActive !== null) {
      if (currentActive.value.length === 0) {
        label.classList.remove('active');
        currentActive.classList.remove('active');
      }
    }
  },
};

export default inputFields;
