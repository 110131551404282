// src/mixins/Initiable.ts
function Initiable(baseClass) {
  class InitiableMixin extends baseClass {
    init(context) {
      if (context.props) {
        Object.entries(context.props).forEach(([key, value]) => {
          this[key] = value;
        });
      }
      if (context.attributes) {
        Object.entries(context.attributes).forEach(([key, value]) => {
          this.setAttribute(key, value);
        });
      }
      if (context.innerHTML) {
        this.innerHTML = context.innerHTML;
      }
      if (context.childNodes?.length) {
        this.append(...context.childNodes);
      }
      return this;
    }
  }
  return InitiableMixin;
}

export {
  Initiable
};
