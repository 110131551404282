import {
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  t
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-region-indicator/jb-region-indicator.scss?inline
var jb_region_indicator_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

ul {
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
  align-items: center;
  cursor: pointer;
  display: var(--display, flex);
  font-size: 0;
  padding-inline-end: var(--spacing-sm);
  position: relative;
  z-index: 10;
}
ul::before {
  animation-direction: reverse;
  background-color: rgba(var(--rgb-primary, 255, 255, 255), 0.2);
  bottom: 0;
  content: "";
  height: 30px;
  left: 0;
  position: absolute;
  transition: height 0.3s ease 0.2s;
  width: 1px;
}
ul::after {
  background: rgba(var(--rgb-primary, 255, 255, 255), 0.2);
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 100%;
  transition: right 0.3s ease-in-out 0.2s;
}
ul:hover::before {
  height: 0;
}
ul:hover::after {
  right: 10%;
}
ul::before {
  bottom: 0;
  content: "";
  display: none;
  height: var(--before-height, 33px);
  left: 0;
  position: absolute;
  width: 1px;
}
@media (min-width: 1281px) {
  ul::before {
    display: block;
  }
}
ul::after {
  display: none;
  right: var(--after-right, 100%);
  top: 35px;
}
@media (min-width: 1281px) {
  ul::after {
    display: block;
  }
}
ul:hover::after {
  right: 30px;
}
@supports (-webkit-appearance: none) {
  ul:hover::after {
    right: 13%;
  }
}
@media all and (-ms-high-contrast: none) {
  ul:hover::after {
    right: 13%;
  }
}

::slotted(jb-region-indicator-item:not(:last-of-type)) {
  --dot-display: block;
  --padding-right: 20px;
}`;

// src/components/jb-region-indicator/jb-region-indicator.ts
var JbRegionIndicator = class extends h {
  firstUpdated() {
    this.addEventListener("click", (e) => {
      e.preventDefault();
      this.dispatchEvent(new CustomEvent("region-indicator:clicked", { bubbles: true, composed: true }));
    });
    this.requestUpdate();
  }
  render() {
    return ke`<ul>
      <slot></slot>
    </ul>`;
  }
};
JbRegionIndicator.styles = r(jb_region_indicator_default);
JbRegionIndicator = __decorateClass([
  t("jb-region-indicator")
], JbRegionIndicator);

export {
  JbRegionIndicator
};
