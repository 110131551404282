import {
  Rt
} from "./chunk.LTAMHVKK.js";
import {
  Linkable
} from "./chunk.OF3GOBXN.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-article-card/jb-article-card.scss?inline
var jb_article_card_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
h3 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

h3 {
  font-size: var(--theme-typo-h3-mobile-fontsize, 1.375rem);
  line-height: var(--theme-typo-h3-mobile-lineheight, 1.75rem);
}
@media (min-width: 1281px) {
  h3 {
    font-size: var(--theme-typo-h3-desktop-fontsize, 1.625rem);
    line-height: var(--theme-typo-h3-desktop-lineheight, 2.25rem);
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
  flex-basis: 100%;
  max-width: 658px;
  --teaser-header-h3-margin-bottom: 20px;
  --teaser-header-h3-margin-top: 20px;
}

article {
  margin-bottom: var(--article-card-bottom, 60px);
  position: relative;
}
@media (min-width: 960px) {
  article {
    margin-bottom: var(--article-card-bottom, 68px);
  }
}
article.horizontal {
  display: flex;
  gap: 10px;
}
@media (min-width: 600px) {
  article.horizontal {
    gap: 16px;
  }
}
article.no-image {
  --teaser-header-h3-margin-top: 0;
  border: 1px solid rgba(20, 30, 85, var(--theme-opacity-20, 0.2));
  transition: border 0.7s ease-in-out 0s;
}
article.no-image:hover {
  border-color: #141e55;
}

.wrapper {
  position: relative;
}

.image-link {
  flex-basis: 25%;
  flex-shrink: 0;
}
@media (min-width: 960px) {
  .image-link {
    flex-basis: 33%;
  }
}

.horizontal .text-link {
  padding-bottom: 0;
}
.no-image .text-link {
  padding: 24px 24px 0;
}

.horizontal jb-teaser-meta {
  position: static;
}
.no-image jb-teaser-meta {
  display: flex;
  padding: 0 24px 24px;
}

a {
  display: block;
  text-decoration: none;
}
a figure {
  overflow: hidden;
}

h3 {
  padding-bottom: 16px;
}

@media print {
  :host {
    break-inside: avoid;
  }
}`;

// src/components/jb-article-card/jb-article-card.ts
var JbArticleCard = class extends Linkable(h) {
  constructor() {
    super(...arguments);
    this.direction = "vertical";
  }
  render() {
    const classes = {
      horizontal: this.direction === "horizontal",
      "no-image": !this.teaserHeader.picture && !this.teaserHeader.thumbnail
    };
    return ke`
      <article class="${Rt(classes)}">
        ${this.direction === "horizontal" ? ke`
              <a
                class="image-link"
                data-linkable
              >
                ${this.teaserHeader.picture ? ke`
                      <jb-picture
                        .alt=${this.teaserHeader.picture.alt}
                        .fit=${"cover"}
                        .imgData=${this.teaserHeader.picture.imgData}
                        .lazy=${this.teaserHeader.picture.lazy}
                      ></jb-picture>
                    ` : D}
                ${this.teaserHeader.thumbnail ? ke`
                      <jb-thumbnail
                        .backgroundPicture="${this.teaserHeader.thumbnail.backgroundPicture}"
                        .videoSrc="${this.teaserHeader.thumbnail.videoSrc}"
                        .aspect="${this.teaserHeader.thumbnail.aspect}"
                        .type="${this.teaserHeader.thumbnail.type}"
                      ></jb-thumbnail>
                    ` : D}
              </a>
            ` : D}
        <div class="wrapper">
          <a
            class="text-link"
            data-linkable
          >
            ${this.direction !== "horizontal" ? ke`
                  <jb-teaser-header
                    .headline=${this.teaserHeader.headline}
                    .picture=${this.teaserHeader.picture}
                    .teaser=${this.teaserHeader.teaser}
                    .thumbnail=${this.teaserHeader.thumbnail}
                    ?gallery=${this.gallery}
                  ></jb-teaser-header>
                ` : ke` <h3>${this.teaserHeader.headline}</h3> `}
          </a>
          ${this.teaserMeta ? ke` <jb-teaser-meta
                .date=${this.teaserMeta.date}
                .category=${this.teaserMeta.category}
                .time=${this.teaserMeta.time}
                .link=${this.teaserMeta.link ? this.teaserMeta.link : this.link}
              ></jb-teaser-meta>` : D}
        </div>
      </article>
    `;
  }
};
JbArticleCard.styles = r(jb_article_card_default);
__decorateClass([
  n()
], JbArticleCard.prototype, "direction", 2);
__decorateClass([
  n({ type: Boolean })
], JbArticleCard.prototype, "gallery", 2);
__decorateClass([
  n({ type: Object })
], JbArticleCard.prototype, "teaserHeader", 2);
__decorateClass([
  n({ type: Object })
], JbArticleCard.prototype, "teaserMeta", 2);
JbArticleCard = __decorateClass([
  customElement("jb-article-card")
], JbArticleCard);

export {
  JbArticleCard
};
