const tableSharePrice = {
  wrapper: null,
  inner: null,
  autoSuggestApiUri: '',
  init() {
    tableSharePrice.dom();
    tableSharePrice.bindEvents();
  },
  dom() {
    tableSharePrice.wrapper = document.querySelector('.o-share-price-table');

    if (tableSharePrice.wrapper !== null) {
      tableSharePrice.inner = tableSharePrice.wrapper.querySelector('.o-share-price-table__inner');
      tableSharePrice.tableSharePriceApiUrl = tableSharePrice.wrapper.getAttribute('data-url-data-tableshareprice');
    }
  },
  bindEvents() {
    if (tableSharePrice.wrapper !== null) {
      const xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          const response = JSON.parse(xhttp.responseText);

          if (tableSharePrice.inner !== null) {
            const outerDiv = document.createElement('div');
            outerDiv.classList.add('table-wrapper');
            tableSharePrice.inner.appendChild(outerDiv);

            const outerTable = document.createElement('table');
            outerTable.classList.add('table');
            outerTable.classList.add('table--no-head');
            outerDiv.appendChild(outerTable);

            const outerTbody = document.createElement('tbody');
            outerTable.appendChild(outerTbody);

            const outerTr = document.createElement('tr');
            outerTbody.appendChild(outerTr);

            const outerTd = document.createElement('td');
            outerTd.classList.add('wrap');
            outerTr.appendChild(outerTd);

            const innerTable = document.createElement('table');
            innerTable.classList.add('table');
            outerTd.appendChild(innerTable);

            const innerThead = document.createElement('thead');
            innerTable.appendChild(innerThead);

            const theadTr = document.createElement('tr');
            innerThead.appendChild(theadTr);

            const innerTbody = document.createElement('tbody');
            innerTable.appendChild(innerTbody);

            const keys = Object.keys(response);
            keys.forEach((key) => {
              const val = response[key];

              const innerTr = document.createElement('tr');
              innerTr.classList.add('text-small');
              innerTbody.appendChild(innerTr);

              const innerTd1 = document.createElement('td');
              innerTr.appendChild(innerTd1);
              innerTd1.innerText = key;

              const innerTd2 = document.createElement('td');
              innerTr.appendChild(innerTd2);
              innerTd2.innerText = val;
            });
          }
        }
      };

      xhttp.open('GET', tableSharePrice.tableSharePriceApiUrl, true);
      xhttp.send();
    }
  },
};

export default tableSharePrice;
