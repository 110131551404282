import JB from './JB';

const juliusB = {
  bgColorSections: null,
  init: function init() {
    juliusB.dom();
    juliusB.events();
  },
  dom: function dom() {
    juliusB.bgColorSections = document.querySelectorAll('[data-bg-color-class]');
  },
  events: function events() {
    if (juliusB.bgColorSections !== null) {
      juliusB.handleScroll();
      document.addEventListener('scroll', JB.debounce(juliusB.handleScroll, 20, false));
    }
  },
  handleScroll() {
    let currentBgColorClass = '';

    juliusB.bgColorSections.forEach((section) => {
      const bgColorClass = section.getAttribute('data-bg-color-class');
      if (bgColorClass && bgColorClass.length > 0) {
        document.body.classList.add('animatebackground');
        if (JB.elementIsInCenterOfViewport(section)) {
          if (currentBgColorClass === '') {
            currentBgColorClass = bgColorClass;
          }
          document.body.classList.add(bgColorClass);
        } else if (currentBgColorClass !== bgColorClass) {
          JB.removeElementCssClassByPrefix(document.body, bgColorClass);
        }
      }
    });
  },
};

export default juliusB;
