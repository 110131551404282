import {
  se
} from "./chunk.Y44YK2W3.js";
import {
  Rt
} from "./chunk.LTAMHVKK.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  Stackable
} from "./chunk.A4SFXC3I.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  e,
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-overlay/jb-overlay.scss?inline
var jb_overlay_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

.overlay {
  --background-color: #fff;
  background-color: var(--background-color);
  display: block;
  height: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: height 600ms ease-in;
  width: 100%;
}
.overlay.visible {
  height: 100vh;
  transition-timing-function: ease-out;
}
.overlay.visible .overlay__header {
  opacity: 1;
  transition: opacity 300ms ease-out 300ms;
}
.overlay.visible .overlay__content {
  opacity: 1;
  top: 0;
  transition: all 450ms ease-out 500ms;
}
.overlay--gray {
  --background-color: #f1f2f2;
}
.overlay--blue {
  --background-color: #141e55;
}
.overlay--blue .overlay__content {
  scrollbar-color: #8a8ea8 transparent;
}
.overlay--blue .overlay__content::-webkit-scrollbar {
  width: 12px;
}
.overlay--blue .overlay__content::-webkit-scrollbar-thumb {
  background: #8a8ea8;
  border-left: 4px solid #141e55;
  border-right: 4px solid #141e55;
}
.overlay__header {
  background-color: var(--background-color);
  height: 60px;
  opacity: 0;
  padding-block: 24px;
  position: fixed;
  top: 0;
  transition: opacity 300ms ease-in 400ms;
  width: 100%;
}
@media (min-width: 600px) {
  .overlay__header {
    height: 90px;
  }
}
@media (min-width: 1281px) {
  .overlay__header {
    height: 100px;
  }
}
.overlay__header > div {
  opacity: 0;
}
.overlay__logo-wrap {
  display: flex;
  justify-content: center;
}
@media (min-width: 600px) {
  .overlay__logo-wrap {
    padding-top: 12px;
  }
}
@media (min-width: 1281px) {
  .overlay__logo-wrap {
    padding-top: 22px;
  }
}
.overlay__logo-wrap.no-logo {
  visibility: hidden;
}
.overlay jb-logo {
  --logo-color: #001489;
  height: 22px;
  margin-top: 1px;
  width: 114px;
}
@media (min-width: 600px) {
  .overlay jb-logo {
    height: 32px;
    margin-top: 2px;
    width: 164px;
  }
}
.overlay__close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
}
@media (min-width: 600px) {
  .overlay__close-btn {
    right: 36px;
    top: 36px;
  }
}
@media (min-width: 1281px) {
  .overlay__close-btn {
    right: 66px;
    top: 46px;
  }
}
.overlay__content {
  --overlay-header-height: 60px;
  background-color: var(--background-color);
  height: 100%;
  height: calc(100vh - var(--overlay-header-height, 0));
  margin-top: var(--overlay-header-height);
  opacity: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  scrollbar-color: #141e55 transparent;
  scrollbar-width: thin;
  transition: all 450ms ease-in;
  width: 100%;
}
@media (min-width: 600px) {
  .overlay__content {
    --overlay-header-height: 90px;
  }
}
@media (min-width: 1281px) {
  .overlay__content {
    --overlay-header-height: 100px;
  }
}
.overlay__content::-webkit-scrollbar {
  width: 4px;
}
.overlay__content::-webkit-scrollbar-track {
  background-color: transparent;
}
.overlay__content::-webkit-scrollbar-thumb {
  background-color: #141e55;
  border-radius: 4px;
}
.overlay__content.relative {
  max-height: calc(100vh - 61px);
  position: relative;
}
@media (min-width: 600px) {
  .overlay__content.relative {
    max-height: calc(100vh - 92px);
  }
}
@media (min-width: 1281px) {
  .overlay__content.relative {
    max-height: calc(100vh - 102px);
  }
}

.new-animation {
  background-color: unset;
  transition: unset;
}
.new-animation.visible {
  transition-timing-function: unset;
}
.new-animation.visible .overlay__header {
  transition: unset;
}
.new-animation.visible .overlay__content {
  transition: unset;
}
.new-animation__header {
  opacity: unset;
  transition: unset;
}
.new-animation__content {
  opacity: unset;
  transition: unset;
}

.fullheight .overlay__header {
  background-color: transparent;
  z-index: 2;
}
.fullheight .overlay__content {
  height: 100%;
  height: calc(100vh - var(--overlay-header-height, 0) + 68px);
  margin-top: 34px;
}

jb-burger-icon,
jb-close-icon {
  padding-right: var(--compensate-scrollbar, 0);
}

jb-logo {
  translate: calc(-0.5 * var(--compensate-scrollbar, 0)) 0;
}`;

// src/components/jb-overlay/jb-overlay.ts
var JbOverlay = class extends Initiable(Stackable(h)) {
  constructor() {
    super(...arguments);
    this.fullHeight = false;
    this.hasCloseIcon = true;
    this.newAnimation = false;
  }
  firstUpdated() {
    if (this._introPromiseResolve) {
      window.setTimeout(() => {
        this._section.classList.add("visible");
        this._section.addEventListener("transitionend", (event) => {
          if (event.propertyName === "height") {
            this._introPromiseResolve(true);
          }
        });
      }, 10);
    }
  }
  async intro() {
    return new Promise((resolve) => {
      this._introPromiseResolve = resolve;
    });
  }
  async outro() {
    return new Promise((resolve) => {
      if (!this.newAnimation) {
        this._section.classList.remove("visible");
      } else {
        resolve(true);
      }
      this._section.addEventListener("transitionend", (event) => {
        if (event.propertyName === "height") {
          resolve(true);
        }
      });
    });
  }
  stacked() {
  }
  unstacked() {
    this.remove();
  }
  _close() {
    this.dispatchEvent(
      new CustomEvent("jb-overlay:close", {
        bubbles: true,
        composed: true
      })
    );
  }
  get _slottedChildren() {
    const slot = this.shadowRoot.querySelector("slot");
    return slot.assignedElements();
  }
  render() {
    return ke`
      <section
        aria-modal="true"
        class=${Rt({
      overlay: true,
      "overlay--gray": this.color === "gray",
      "overlay--blue": this.color === "blue",
      "new-animation": !!this.newAnimation,
      fullheight: !!this.fullHeight,
      visible: true
    })}
        style=${se({ zIndex: this.zIndex().toString() })}
      >
        <div class="overlay__container">
          <div class="overlay__header">
            <div class="${Rt({ "overlay__logo-wrap": true, "no-logo": !this.hasLogo })}">
              <jb-logo
                .href=${this.logoLink}
                .svgLogo=${this.svgLogo}
              ></jb-logo>
            </div>
            <div class="overlay__close-btn">
              ${this.hasCloseIcon ? ke`<jb-close-icon
                    role="button"
                    aria-label="${this.ariaLabelClose ?? "Close"}"
                    @click=${this._close}
                  ></jb-close-icon>` : D}
            </div>
          </div>
          <div class="${Rt({ overlay__content: true, relative: !!this.relativeContent })}">
            <slot></slot>
          </div>
        </div>
      </section>
    `;
  }
};
JbOverlay.styles = r(jb_overlay_default);
__decorateClass([
  n()
], JbOverlay.prototype, "ariaLabelClose", 2);
__decorateClass([
  n()
], JbOverlay.prototype, "color", 2);
__decorateClass([
  n({ type: Boolean })
], JbOverlay.prototype, "fullHeight", 2);
__decorateClass([
  n({ type: Boolean })
], JbOverlay.prototype, "hasCloseIcon", 2);
__decorateClass([
  n({ type: Boolean })
], JbOverlay.prototype, "hasLogo", 2);
__decorateClass([
  n()
], JbOverlay.prototype, "logoLink", 2);
__decorateClass([
  n({ type: Boolean })
], JbOverlay.prototype, "newAnimation", 2);
__decorateClass([
  n({ type: Boolean })
], JbOverlay.prototype, "relativeContent", 2);
__decorateClass([
  n()
], JbOverlay.prototype, "svgLogo", 2);
__decorateClass([
  e("section")
], JbOverlay.prototype, "_section", 2);
JbOverlay = __decorateClass([
  customElement("jb-overlay")
], JbOverlay);

export {
  JbOverlay
};
