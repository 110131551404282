import JB from './JB';

/**
 * Implements the following market related features, that react to the user's current market (according to their cookie):
 * * Whenever an element has a [data-only-for-markets] attribute, it will check if the user's market is in the provided list.
 *   If not, the element will be removed from the DOM.
 * * Whenever a link is initialized and has marketUris in its link data, it will check if there's a URL provided for the user's market.
 *   If yes, then the href attribute value will be replaced with the uri for the market.
 */
const marketContentModification = {
  currentMarket: null,
  scope: null,
  init(scope) {
    this.scope = scope || document;
    this.removeElementsNotForUsersMarket();
  },
  bindEarlyEvents() {
    this.currentMarket = JB.getI18nCookieData().market;
    document.body.addEventListener('goMarketContentAdded', () => {
      marketContentModification.removeElementsNotForUsersMarket();
    });
    document.body.addEventListener('jb-link:initialized', (event) => {
      if (!event.detail.marketUris) {
        return;
      }

      event.stopPropagation();
      if (!(this.currentMarket in event.detail.marketUris)) {
        return;
      }
      event.detail.linkTag.setAttribute('href', event.detail.marketUris[this.currentMarket]);
      if (event.detail.linkableComponent) {
        const { linkableComponent } = event.detail;
        linkableComponent.link.href = event.detail.marketUris[this.currentMarket];
      }
    });
  },
  removeElementsNotForUsersMarket() {
    this.scope = this.scope || document;
    this.scope.querySelectorAll('[data-only-for-markets]').forEach((element) => {
      if (element.dataset.onlyForMarkets.split(',').indexOf(this.currentMarket) === -1) {
        // element is not available for current market => remove
        element.parentElement.removeChild(element);
      } else {
        element.removeAttribute('data-only-for-markets');
      }
    });
  },
};

export default marketContentModification;
