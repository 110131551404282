import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-e-service-icon/jb-e-service-icon.scss?inline
var jb_e_service_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

svg {
  cursor: pointer;
  display: var(--icon-display, inline-block);
  fill: rgb(var(--rgb-primary, 255, 255, 255));
  height: 25px;
  margin-right: var(--icon-margin-right, 0);
  overflow: visible;
  width: 19px;
}

#lock-arch {
  transition: all 0.2s ease-in-out;
}
svg:hover #lock-arch {
  transform: translateY(-2px);
}`;

// src/components/icons/jb-e-service-icon/jb-e-service-icon.ts
var JbEServiceIcon = class extends h {
  firstUpdated() {
    this.addEventListener("click", (e) => {
      e.preventDefault();
      this.dispatchEvent(new CustomEvent("e-service-icon:clicked", { bubbles: true, composed: true }));
    });
  }
  render() {
    return ke`
      <svg
        width="19px"
        height="25px"
        viewbox="0 0 19 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g id="05_08_Navigation_Main">
          <g
            id="01_Navigation_Main_New_Desktop1366"
            transform="translate(-1102.000000, -48.000000)"
          >
            <g
              id="Group"
              transform="translate(1102.000000, 48.000000)"
            >
              <path
                d="M14.3,6 C14.3,3.3490332 12.1509668,1.2 9.5,1.2 C6.8490332,1.2 4.7,3.3490332 4.7,6 L4.7,9.2 L3.5,9.2 L3.5,6 C3.5,2.6862915 6.1862915,0 9.5,0 C12.8137085,0 15.5,2.6862915 15.5,6 L15.5,6 L15.5,9.2 L14.3,9.2 L14.3,6 L14.3,6 Z"
                id="lock-arch"
              ></path>
              <path
                d="M15.5,6 L15.499,9 L17,9 C18.1045695,9 19,9.8954305 19,11 L19,23 C19,24.1045695 18.1045695,25 17,25 L2,25 C0.8954305,25 0,24.1045695 0,23 L0,11 C0,9.8954305 0.8954305,9 2,9 L14.299,9 L14.3,6 L15.5,6 Z M17,10.2 L2,10.2 C1.59215895,10.2 1.25559842,10.5051881 1.20623314,10.8996497 L1.2,11 L1.2,23 C1.2,23.407841 1.50518815,23.7444016 1.8996497,23.7937669 L2,23.8 L17,23.8 C17.407841,23.8 17.7444016,23.4948119 17.7937669,23.1003503 L17.8,23 L17.8,11 C17.8,10.592159 17.4948119,10.2555984 17.1003503,10.2062331 L17,10.2 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    `;
  }
};
JbEServiceIcon.styles = r(jb_e_service_icon_default);
JbEServiceIcon = __decorateClass([
  customElement("jb-e-service-icon")
], JbEServiceIcon);

export {
  JbEServiceIcon
};
