import {
  Rt
} from "./chunk.LTAMHVKK.js";
import {
  Linkable
} from "./chunk.OF3GOBXN.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-service-navigation-item/jb-service-navigation-item.scss?inline
var jb_service_navigation_item_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
.service-navigation-item__text {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

.service-navigation-item__text {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 0.688rem;
  letter-spacing: 0.063rem;
  line-height: 1rem;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: inline-block;
}

.service-navigation-item {
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
  list-style: none;
  position: relative;
}
.service-navigation-item::before {
  animation-direction: reverse;
  background-color: rgba(var(--rgb-primary, 255, 255, 255), 0.2);
  bottom: 0;
  content: "";
  height: 30px;
  left: 0;
  position: absolute;
  transition: height 0.3s ease 0.2s;
  width: 1px;
}
.service-navigation-item::after {
  background: rgba(var(--rgb-primary, 255, 255, 255), 0.2);
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 100%;
  transition: right 0.3s ease-in-out 0.2s;
}
.service-navigation-item:hover::before {
  height: 0;
}
.service-navigation-item:hover::after {
  right: 10%;
}
.service-navigation-item--animate-left::before {
  height: 33px;
}
.service-navigation-item--animate-left:hover::after {
  right: 27px;
  transition-delay: 0.2s;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.service-navigation-item--animate-left:hover::before {
  height: 0;
}
.service-navigation-item__text {
  color: rgb(var(--rgb-primary, 255, 255, 255));
  display: block;
  line-height: 2.25rem;
  padding: 0 27px 0 19px;
  text-decoration: none;
  text-transform: uppercase;
}
@media (min-width: 1281px) {
  .service-navigation-item__text {
    padding: 0 27px 0 18px;
  }
}
.service-navigation-item.mobile-menu {
  margin-left: 24px;
}
.service-navigation-item.mobile-menu.first-element {
  margin-left: 0;
}
.service-navigation-item.mobile-menu .service-navigation-item__text {
  line-height: 1rem;
  padding: 0 0 9px;
}
.service-navigation-item.mobile-menu::before {
  content: none;
}
.service-navigation-item.mobile-menu::after {
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
}`;

// src/components/jb-service-navigation-item/jb-service-navigation-item.ts
var JbServiceNavigationItem = class extends Linkable(h) {
  render() {
    return ke`
      <li
        class=${Rt({
      "mobile-menu": this.parentElement?.slot === "",
      "first-element": this.previousElementSibling === null,
      "service-navigation-item": true,
      "service-navigation-item--animate-left": true
    })}
      >
        <a
          data-linkable
          class="service-navigation-item__text"
          >${this.text}</a
        >
      </li>
    `;
  }
};
JbServiceNavigationItem.styles = r(jb_service_navigation_item_default);
__decorateClass([
  n()
], JbServiceNavigationItem.prototype, "text", 2);
JbServiceNavigationItem = __decorateClass([
  customElement("jb-service-navigation-item")
], JbServiceNavigationItem);

export {
  JbServiceNavigationItem
};
