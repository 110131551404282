function getExternalReferrer() {
  const referrer = document.referrer;
  if (referrer.indexOf(location.hostname) !== -1 || referrer === '') {
    return null;
  }
  return referrer;
}

function getUtmParameters() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const utmParams = {};

  urlParams.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utmParams[key] = value;
    }
  });
  return utmParams;
}

document.addEventListener('jb-cookie-settings:update', () => {
  const endpoint = document.body.dataset.putReferrerEndpoint;
  if (!endpoint) {
    return;
  }

  const externalReferrer = getExternalReferrer();
  const utmParameters = getUtmParameters();
  if (!externalReferrer && Object.keys(utmParameters).length === 0) {
    return;
  }

  const data = {
    'entryUrl': window.location.href.split('?')[0],
  };
  if (externalReferrer) {
    data['referrer'] = externalReferrer;
  }
  if (Object.keys(utmParameters).length > 0) {
    data['utmParameters'] = utmParameters;
  }

  fetch(endpoint, { method: 'PUT', body: JSON.stringify(data) }).then();
});
