import {
  Spaceable
} from "./chunk.UQO6F5RO.js";
import {
  Rt
} from "./chunk.LTAMHVKK.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  getAllParents,
  scrollToTop
} from "./chunk.WTC7JQ7D.js";
import {
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-article-back-to-top/jb-article-back-to-top.scss?inline
var jb_article_back_to_top_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
.sticky .text {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

.sticky .text {
  font-size: var(--theme-typo-uppercase-700-fontsize, 0.688rem);
  letter-spacing: var(--theme-typo-uppercase-700-letterspacing, 0.063rem);
  line-height: var(--theme-typo-uppercase-700-lineheight, 1rem);
  text-transform: uppercase;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:root {
  display: block;
}

.static {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 2 * var(--row-small-margin, 6.68%));
  justify-content: center;
  padding-block-end: 60px;
  position: relative;
}
@media (min-width: 600px) and (max-width: 959px) {
  .static {
    width: calc(100% - 2 * 8.35%);
  }
}
@media (min-width: 1437px) {
  .static {
    max-width: var(--row-max-width, 1436px);
  }
  .static:not(.filtered-list) {
    width: 100%;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1436px) {
  .static {
    margin: 0 6.955%;
    max-width: calc(100% - 6.955% - 6.955%);
  }
}
@media (min-width: 1281px) {
  .static {
    padding-block-end: 68px;
  }
}
.static jb-button-tertiary {
  display: inline-block;
  position: relative;
}

.sticky {
  --_element-height: 66px;
  background-color: rgb(var(--theme-color-smoke-light, 241 242 242));
  cursor: pointer;
  display: block;
  height: var(--_element-height);
  inset-block-end: var(--sticky-back-to-top-position, 25vh);
  inset-inline-end: 0;
  position: fixed;
  text-decoration: none;
  transition: translate 0.3s;
  translate: 100%;
  width: var(--_element-height);
  z-index: 110;
}
.sticky.show {
  translate: 0;
}
.sticky.show .text-wrapper {
  display: grid;
}
.sticky .icon-wrapper,
.sticky .text-wrapper {
  padding: var(--spacing-sm);
}
.sticky .text-wrapper {
  background-color: rgb(var(--theme-color-royal-blue, 20 30 85));
  color: rgb(var(--theme-color-white, 255 255 255));
  display: none;
  height: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  padding-inline-end: 0;
  place-items: center;
  position: absolute;
  transition: translate 0.5s;
  translate: 100%;
  width: max-content;
  z-index: 111;
}
.sticky .icon-wrapper {
  background-color: inherit;
  display: grid;
  height: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  place-items: center;
  position: absolute;
  transition: background-color 0.3s ease-in-out;
  width: var(--_element-height);
  z-index: 112;
}
.sticky .icon {
  height: 16px;
  transform: rotate(180deg);
  --icon-size: 16px;
}
.sticky:hover {
  max-width: 400px;
}
.sticky:hover .icon-wrapper {
  background-color: rgb(var(--theme-color-royal-blue, 20 30 85));
  --icon-color: rgb(var(--theme-color-white, 255 255 255));
}
.sticky:hover .text-wrapper {
  translate: calc(-100% + 1px);
}

@media print {
  :host {
    display: none;
  }
}`;

// src/components/jb-article-back-to-top/jb-article-back-to-top.ts
var JbArticleBackToTop = class extends Spaceable(h) {
  constructor() {
    super(...arguments);
    this.displayStickyButtonAfterXViewportHeights = 1;
  }
  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties);
    if (this.sticky) {
      this._registerScrollOutCheck();
    }
  }
  _click(e) {
    e.preventDefault();
    if (this.scrollElement) {
      try {
        scrollToTop(this.scrollElement);
        return;
      } catch (err) {
      }
    }
    const elms = getAllParents(this);
    let scrolled = false;
    elms.forEach((elm) => {
      if (scrolled) return;
      const element = elm;
      if (element.clientHeight + 20 < element.scrollHeight) {
        scrollToTop(element);
        scrolled = true;
      }
    });
  }
  _registerScrollOutCheck() {
    let active = true;
    window.addEventListener("scroll", () => {
      if (!active) {
        return;
      }
      active = false;
      window.setTimeout(() => {
        if (this._shouldDisplaySticky()) {
          this.renderRoot.querySelector(".sticky")?.classList.add("show");
        } else {
          this.renderRoot.querySelector(".sticky")?.classList.remove("show");
        }
        active = true;
      }, 100);
    });
  }
  _shouldDisplaySticky() {
    const scrollPosition = window.scrollY;
    const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const displayAtScrollPosition = viewPortHeight * this.displayStickyButtonAfterXViewportHeights - viewPortHeight;
    return scrollPosition >= displayAtScrollPosition;
  }
  render() {
    return ke`
      <div
        class=${Rt({
      sticky: this.sticky,
      static: !this.sticky
    })}
        @click=${this._click}
      >
        ${this.sticky ? ke`
              <div class="text-wrapper">
                <span class="text">${this.text}</span>
              </div>
              <div class="icon-wrapper">
                <div class="icon"><jb-in-page-icon></jb-in-page-icon></div>
              </div>
            ` : ke`<jb-button-tertiary arrowDirection="up">${this.text}</jb-button-tertiary>`}
      </div>
    `;
  }
};
JbArticleBackToTop.styles = r(jb_article_back_to_top_default);
__decorateClass([
  n({ type: Number })
], JbArticleBackToTop.prototype, "displayStickyButtonAfterXViewportHeights", 2);
__decorateClass([
  n({ type: Object })
], JbArticleBackToTop.prototype, "scrollElement", 2);
__decorateClass([
  n({ type: Boolean })
], JbArticleBackToTop.prototype, "sticky", 2);
__decorateClass([
  n()
], JbArticleBackToTop.prototype, "text", 2);
JbArticleBackToTop = __decorateClass([
  customElement("jb-article-back-to-top")
], JbArticleBackToTop);

export {
  JbArticleBackToTop
};
