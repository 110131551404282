// Legacy components
import './scripts/polyfills';
import juliusB from './scripts/juliusB';
import marketContentModification from './scripts/marketContentModification';
import linkFeatures from './scripts/linkFeatures';
import gaEvents from './scripts/gaEvents';

// Atoms
import inputFields from './atoms/forms/text-field/text-field';
import selectList from './atoms/forms/select/select';

// Molecules
import articleProgressBar from './molecules/article-progress-bar/article-progress-bar';
import navigationTags from './molecules/navigation/navigation-tags/navigation-tags';

// Organisms
import tableSharePrice from './organisms/share-price-table/share-price-table';

window.addEventListener('load', () => {
  juliusB.init();

  // Atoms
  inputFields.init();
  selectList.init();

  // Molecules
  articleProgressBar.init();
  navigationTags.init();

  // Organisms
  tableSharePrice.init();

  marketContentModification.init();
  linkFeatures.init();
});

gaEvents.registerEvents();
marketContentModification.bindEarlyEvents();
