import {
  Linkable,
  getLinkIcon,
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.OF3GOBXN.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  isPartiallyInViewPort
} from "./chunk.WTC7JQ7D.js";
import {
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-cta-small/jb-cta-small.scss?inline
var jb_cta_small_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
.cta-small .text {
  font-family: var(--theme-font-black-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-black-weight, 900);
}

.cta-small .text {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 0.688rem;
  letter-spacing: 0.125rem;
  line-height: 1rem;
  text-decoration: none;
  text-transform: uppercase;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

.cta-small {
  background-color: #f0f1f2;
  cursor: pointer;
  display: flex;
  gap: var(--spacing-xs);
  inline-size: 100%;
  inset-block-end: 0;
  justify-content: center;
  position: fixed;
  text-decoration: none;
  transform: translateY(48px);
  transition: transform 0.5s;
  z-index: 110;
}
.cta-small.cta-small--visible {
  transform: translateY(0);
}
@media (min-width: 960px) {
  .cta-small {
    inline-size: auto;
    inset-block-end: 180px;
    inset-inline-end: -100%;
    transform: translateX(64px);
    transition: background-color 0.3s, transform 1s, right 1ms 1s;
    width: auto;
  }
  .cta-small.cta-small--visible {
    inset-inline-end: 0;
    transform: translateX(0);
    transition: background-color 0.3s, transform 1s, right 1ms;
  }
  .cta-small.cta-small--visible .icon {
    transition: background-color 0.5s 0.2s;
  }
  .cta-small.cta-small--visible .icon svg {
    opacity: 1;
    transition: opacity 0.5s 0.5s;
  }
  .cta-small.mail .icon {
    --icon-size: 20px;
  }
  .cta-small.cta-small--hover.cta-small .text {
    background-color: #141e55;
    transform: translateX(-100%);
    transition: transform 1s, background-color 0.3s;
  }
  .cta-small.cta-small--hover.cta-small .text-wrap {
    opacity: 1;
    transition: opacity 0.7s 0.3s;
  }
  .cta-small.cta-small--hover.cta-small .icon {
    --rgb-primary: 255, 255, 255;
    background-color: #141e55;
    transition: background-color 0.3s;
  }
}
.cta-small .text {
  padding-block: var(--spacing-sm);
}
@media (min-width: 960px) {
  .cta-small .text {
    background-color: #f0f1f2;
    color: #fff;
    inset-inline-start: 1px;
    line-height: 1.25rem;
    padding: var(--spacing-sm);
    padding-inline-end: 0;
    position: absolute;
    transform: translateX(0);
    transition: transform 0.5s 0.2s, background-color 0.5s 0.2s;
    white-space: nowrap;
  }
}
@media (min-width: 960px) {
  .cta-small .text-wrap {
    opacity: 0;
    transition: opacity 0.2s;
  }
}
.cta-small .icon {
  --icon-transition: fill 0.3s;
  line-height: 0.875rem;
  padding-block: var(--spacing-sm);
}
@media (min-width: 960px) {
  .cta-small .icon {
    background-color: #f0f1f2;
    padding: var(--spacing-sm);
    transition: background-color 0.5s 0.2s;
    z-index: 1;
  }
}

@media print {
  :host {
    display: none;
  }
}`;

// src/components/jb-cta-small/jb-cta-small.ts
var JbCtaSmall = class extends Linkable(h) {
  constructor() {
    super(...arguments);
    this.alwaysVisible = false;
    this._ctaSmall = {
      el: null,
      hero: null,
      text: null,
      footer: null
    };
  }
  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    this._ctaSmall.el = this.renderRoot.querySelector(".cta-small");
    this._ctaSmall.hero = document.querySelector(
      "jb-info-page-hero, jb-campaign-page-hero-fullscreen, jb-article-page-hero, jb-moment-page-hero, jb-news-page-hero, jb-simple-page-hero, jb-home-page-hero"
    );
    this._ctaSmall.text = this.renderRoot.querySelector(".text");
    this._ctaSmall.footer = document.querySelector("jb-footer");
    if (!this._ctaSmall.el) return;
    if (this.link.data.type === "download" && this.link?.href) {
      this._ctaSmall.el?.setAttribute("download", "");
    }
    this._ctaSmall.el.addEventListener("mouseover", () => this._mouseOver());
    this._ctaSmall.el.addEventListener("mouseout", () => this._mouseOut());
    window.addEventListener("scroll", () => this._showHide());
    setTimeout(() => {
      this._showHide();
    }, 0);
  }
  _aboveTheFold() {
    const viewportHeight = window.innerHeight;
    if (this._ctaSmall.hero) {
      const heroBottomEdgeOffset = this._ctaSmall.hero.getBoundingClientRect().bottom;
      return heroBottomEdgeOffset > viewportHeight / 2;
    }
    const scrollPosition = window.scrollY;
    return scrollPosition < viewportHeight / 2;
  }
  _mouseOut() {
    this._ctaSmall.el?.classList.remove("cta-small--hover");
  }
  _mouseOver() {
    this._ctaSmall.el?.classList.add("cta-small--hover");
  }
  _showHide() {
    if (!this.alwaysVisible && (this._aboveTheFold() || this._ctaSmall.footer && isPartiallyInViewPort(this._ctaSmall.footer))) {
      this._ctaSmall.el?.classList.remove("cta-small--visible");
      this._ctaSmall.el?.classList.remove("cta-small--hover");
    } else {
      this._ctaSmall.el?.classList.add("cta-small--visible");
    }
  }
  _tracking(e) {
    const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e, {
      component_name: "cta-small",
      interaction_element: "cta"
    });
    if (jbTrackingEvent) {
      this.dispatchEvent(jbTrackingEvent);
    }
  }
  render() {
    return ke`
      <a
        @jb-link:clicked=${this._tracking}
        data-linkable
        class=${"cta-small " + this.link.data.type}
      >
        <span class="text">
          <span class="text-wrap">${this.text}</span>
        </span>
        <span class="icon">${getLinkIcon(this.link.data.type)}</span>
      </a>
    `;
  }
};
JbCtaSmall.styles = r(jb_cta_small_default);
__decorateClass([
  n({ type: Boolean })
], JbCtaSmall.prototype, "alwaysVisible", 2);
__decorateClass([
  n()
], JbCtaSmall.prototype, "text", 2);
JbCtaSmall = __decorateClass([
  customElement("jb-cta-small")
], JbCtaSmall);

export {
  JbCtaSmall
};
