import {
  getViewport
} from "./chunk.YDQDJZUC.js";

// src/assets/scripts/LazyLoadingHandler.ts
var LazyLoadingHandler = class {
  constructor() {
    this._approachingViewportObserver = new IntersectionObserver(
      this._observerCallback.bind({ ...this, observation: "approachingViewport" }),
      { rootMargin: `${getViewport().height}px` }
    );
    this._entersViewportObserver = new IntersectionObserver(
      this._observerCallback.bind({ ...this, observation: "entersViewport" }),
      { threshold: 0 }
    );
    this._fullInViewportObserver = new IntersectionObserver(
      this._observerCallback.bind({ ...this, observation: "fullInViewport" }),
      { threshold: 1 }
    );
  }
  observe(element, callback, observation) {
    element.lazyLoadingCallback = callback;
    switch (observation) {
      case "approachingViewport":
        element.observer = this._approachingViewportObserver;
        break;
      case "entersViewport":
        element.observer = this._entersViewportObserver;
        break;
      case "fullInViewport":
        element.observer = this._fullInViewportObserver;
        break;
      default:
        this._approachingViewportObserver.observe(element);
    }
    element.observer.observe(element);
  }
  _observerCallback(observerEntries) {
    observerEntries.forEach((observerEntry) => {
      if (observerEntry.isIntersecting) {
        observerEntry.target.observer.unobserve(observerEntry.target);
        observerEntry.target.lazyLoadingCallback(observerEntry.target);
      }
    });
  }
};
var instance = new LazyLoadingHandler();
Object.freeze(instance);
var LazyLoadingHandler_default = instance;

export {
  LazyLoadingHandler_default
};
