import { JBCookie } from '@bjb/bjb-web-components/dist/assets/scripts/JB.js';

let isFirstTimeCookieConsent = !JBCookie.getCookie('hide_cookie_banner');

function submitCookieConsentShow(e: CustomEvent) {
  const endpoint = document.body.dataset.privacyPreferencesEndpoint;
  if (!endpoint) {
    return;
  }

  const data = { type: 'show', url: window.location.toString() };

  fetch(endpoint, { method: 'PUT', body: JSON.stringify(data) }).then();
}

function submitPrivacyPreferences(e: CustomEvent) {
  const endpoint = document.body.dataset.privacyPreferencesEndpoint;
  if (!endpoint) {
    return;
  }

  const data = {
    privacyPreferences: {
      allowMarketingCookies: !!JBCookie.getCookie('allow_marketing_cookies'),
      allowTrackingCookies: !!JBCookie.getCookie('allow_tracking_cookies'),
    },
    type: isFirstTimeCookieConsent ? 'initial' : 'update',
    url: window.location.toString(),
  };
  isFirstTimeCookieConsent = false;

  fetch(endpoint, { method: 'PUT', body: JSON.stringify(data) }).then();
}

document.addEventListener('jb-cookie-consent:open', submitCookieConsentShow);
document.addEventListener('jb-cookie-settings:update', submitPrivacyPreferences);
