import { JBCookie } from '@bjb/bjb-web-components/dist/assets/scripts/JB.js';
import { addToTrackingData } from '../modules/tracking';

const i18nData = JBCookie.getI18nCookieData();
const locationData = {
  event: 'page_data_ready',
  location_country: i18nData.country.toLocaleLowerCase(),
  location_region: i18nData.region,
};

addToTrackingData(locationData);
