import { JBCookie } from '@bjb/bjb-web-components/dist/assets/scripts/JB.js';

function trackConsent() {
  document.body.dispatchEvent(
    new CustomEvent('jb-tracking', {
      detail: {
        event: 'ce_consent',
        eventInfo: {
          analytics: !!JBCookie.getCookie('allow_tracking_cookies'),
          marketing: !!JBCookie.getCookie('allow_marketing_cookies'),
        },
      },
    })
  );
}

document.addEventListener('jb-cookie-settings:update', trackConsent);
