import {
  Spaceable
} from "./chunk.UQO6F5RO.js";
import {
  Rt
} from "./chunk.LTAMHVKK.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ChangesBackgroundColor
} from "./chunk.JOZVASZR.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-contact-teaser-legacy/jb-contact-teaser-legacy.scss?inline
var jb_contact_teaser_legacy_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
p {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

p, h4 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

h2 {
  font-family: var(--theme-font-xlight-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-xlight-weight, 200);
}

h2 {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 2.25rem;
  line-height: 2.875rem;
}
@media (min-width: 1281px) {
  h2 {
    font-size: 2.813rem;
    line-height: 3.813rem;
  }
}

h4 {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 1.375rem;
  line-height: 1.75rem;
}
@media (min-width: 1281px) {
  h4 {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }
}

p {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 1.063rem;
  line-height: 1.875rem;
}
@media (min-width: 1281px) {
  p {
    font-size: 1.188rem;
    line-height: 2.125rem;
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

div {
  text-align: center;
  width: 100%;
}

.white_teaser {
  --rgb-primary: 255, 255, 255;
}
.white_teaser h2,
.white_teaser h4,
.white_teaser p {
  color: #fff;
}

h4 {
  letter-spacing: 0.188rem;
  margin-bottom: 45px;
  text-transform: uppercase;
  word-wrap: break-word;
}
@media (min-width: 960px) {
  h4 {
    letter-spacing: 0.25rem;
    margin-bottom: 51px;
  }
}
@media (min-width: 1281px) {
  h4 {
    line-height: 28px;
    line-height: 1.75rem;
  }
}

h2 {
  word-wrap: break-word;
}
@media (min-width: 1281px) {
  h2 {
    font-size: 48px;
    font-size: 3rem;
    line-height: 65px;
    line-height: 4.0625rem;
  }
}

p {
  color: #141e55;
  margin-top: 16px;
}
@media (min-width: 960px) {
  p {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
  }
}

jb-button-panel {
  padding-top: 45px;
  text-align: center;
}
@media (min-width: 960px) {
  jb-button-panel {
    padding-top: 51px;
  }
}`;

// src/components/jb-contact-teaser-legacy/jb-contact-teaser-legacy.ts
var JbContactTeaserLegacy = class extends ChangesBackgroundColor(Spaceable(h)) {
  constructor() {
    super(...arguments);
    this.color = "blue";
  }
  _getContentSpacingProperties() {
    return { colsMdUp: 8 };
  }
  render() {
    const classes = { white_teaser: this.color === "white" };
    return ke`
      <div class="${Rt(classes)}">
        <h4>${this.headline}</h4>
        <h2>${this.subheadline}</h2>
        ${this.text ? ke`<p>${this.text}</p>` : D}
        <jb-button-panel .align=${"center"}><slot></slot></jb-button-panel>
      </div>
    `;
  }
};
JbContactTeaserLegacy.styles = r(jb_contact_teaser_legacy_default);
__decorateClass([
  n()
], JbContactTeaserLegacy.prototype, "color", 2);
__decorateClass([
  n()
], JbContactTeaserLegacy.prototype, "headline", 2);
__decorateClass([
  n()
], JbContactTeaserLegacy.prototype, "subheadline", 2);
__decorateClass([
  n()
], JbContactTeaserLegacy.prototype, "text", 2);
JbContactTeaserLegacy = __decorateClass([
  customElement("jb-contact-teaser-legacy")
], JbContactTeaserLegacy);

export {
  JbContactTeaserLegacy
};
