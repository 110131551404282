import {
  Rt
} from "./chunk.LTAMHVKK.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-section-headline/jb-section-headline.scss?inline
var jb_section_headline_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
span, h2 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

h2 {
  font-size: var(--theme-typo-h2-section-title-mobile-fontsize, 1.5rem);
  letter-spacing: var(--theme-typo-h2-section-title-mobile-letterspacing, 0.125rem);
  line-height: var(--theme-typo-h2-section-title-mobile-lineheight, 2.25rem);
}
@media (min-width: 1281px) {
  h2 {
    font-size: var(--theme-typo-h2-section-title-desktop-fontsize, 1.938rem);
    letter-spacing: var(--theme-typo-h2-section-title-desktop-letterspacing, 0.25rem);
    line-height: var(--theme-typo-h2-section-title-desktop-lineheight, 2.5rem);
  }
}

span {
  font-size: var(--theme-typo-p-mini-desktop-fontsize, 0.813rem);
  line-height: var(--theme-typo-p-mini-desktop-lineheight, 1.25rem);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

div {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

h2 {
  letter-spacing: var(--headline-letter-spacing, 2px);
  min-width: 100%;
  text-align: var(--headline-text-align, center);
  text-transform: var(--headline-text-transform, uppercase);
}
.left h2 {
  --headline-text-align: left;
}
@media (min-width: 1281px) {
  h2 {
    letter-spacing: var(--headline-letter-spacing, 4px);
  }
}

span {
  background-color: rgba(113, 124, 125, 0.1);
  color: #141e55;
  display: inline-block;
  margin-top: 23px;
  padding: 3px 8px;
  text-transform: uppercase;
}
@media (min-width: 600px) {
  span {
    margin-top: 3px;
    position: absolute;
    right: 0;
  }
}`;

// src/components/jb-section-headline/jb-section-headline.ts
var JbSectionHeadline = class extends h {
  render() {
    return ke`<div class="${Rt({ left: this.headlineAlign === "left" })}">
      <h2>${this.headline}</h2>
      ${this.languageBadge ? ke`<span>${this.languageBadge}</span>` : D}
    </div>`;
  }
};
JbSectionHeadline.styles = r(jb_section_headline_default);
__decorateClass([
  n()
], JbSectionHeadline.prototype, "headline", 2);
__decorateClass([
  n()
], JbSectionHeadline.prototype, "headlineAlign", 2);
__decorateClass([
  n()
], JbSectionHeadline.prototype, "languageBadge", 2);
JbSectionHeadline = __decorateClass([
  customElement("jb-section-headline")
], JbSectionHeadline);

export {
  JbSectionHeadline
};
